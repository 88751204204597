import { DeliverableType } from '../types';
import { filterCampaignCollabs } from './collabsUtils';

export const formatCount = (count: number): string => {
  if (!count) {
    return '0';
  }
  if (count >= 1000 && count < 1000000) {
    return (count / 1000).toFixed(1) + "k";
  } else if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + "M";
  }
  return count.toString();
};

export interface PostMetrics {
  likes: number;
  comments: number;
  shares: number;
  views: number;
  saves?: number;
}

export interface PlatformMetrics {
  instagram: PostMetrics;
  tiktok: PostMetrics;
}

export const calculatePostMetrics = (collabs: any[]): PostMetrics => {
  const metrics: PostMetrics = {
    likes: 0,
    comments: 0,
    shares: 0,
    views: 0
  };

  collabs.forEach(collab => {
    if (collab.deliverableLinks) {
      const deliverableTypes: DeliverableType[] = ['tiktoks', 'instagramReels', 'instagramStories', 'instagramPosts'];
      
      deliverableTypes.forEach(type => {
        const links = collab.deliverableLinks[type] || [];
        links.forEach((link: any) => {
          metrics.likes += link.like_count || 0;
          metrics.comments += link.comment_count || 0;
          metrics.views += Number(link.play_count) || 0;
          metrics.shares += link.share_count || 0;
        });
      });
    }
  });

  return metrics;
};

export const calculatePlatformMetrics = (collabs: any[]): PlatformMetrics => {
  const metrics: PlatformMetrics = {
    instagram: { likes: 0, comments: 0, shares: 0, views: 0, saves: 0 },
    tiktok: { likes: 0, comments: 0, shares: 0, views: 0 }
  };

  collabs.forEach(collab => {
    if (collab.deliverableLinks) {
      // Instagram metrics
      ['instagramReels', 'instagramStories', 'instagramPosts'].forEach(type => {
        const links = collab.deliverableLinks[type as DeliverableType] || [];
        links.forEach((link: any) => {
          metrics.instagram.likes += link.like_count || 0;
          metrics.instagram.comments += link.comment_count || 0;
          metrics.instagram.shares += link.share_count || 0;
          metrics.instagram.views += Number(link.play_count) || 0;
          metrics.instagram.saves += link.saves_count || 0;
        });
      });

      // TikTok metrics
      const tiktokLinks = collab.deliverableLinks.tiktoks || [];
      tiktokLinks.forEach((link: any) => {
        metrics.tiktok.likes += link.like_count || 0;
        metrics.tiktok.comments += link.comment_count || 0;
        metrics.tiktok.shares += link.share_count || 0;
        metrics.tiktok.views += Number(link.play_count) || 0;
      });
    }
  });

  return metrics;
};