import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Circle,
  Icon,
  Divider,
  Tooltip
} from '@chakra-ui/react';
import { Influencer } from '../../../types';
import { FaInstagram, FaTiktok } from 'react-icons/fa6';
import { AccountsDataContext } from 'contexts/accountsDataContext';
import { getClientFoodfluenceLocations } from 'services/firebaseService';
import { formatCount } from 'views/admin/foodfluence/utils/metricsUtils';

interface InfluencerMetricsProps {
  influencer: Influencer;
}

interface Location {
  city: string;
  state: string;
  country: string;
  fullAddress: string;
  id: string;
  lat: number;
  lng: number;
  streetName: string;
  streetNumber: string;
  zip: string;
}

const STATE_ABBREVIATIONS: { [key: string]: string } = {
  'ALABAMA': 'AL',
  'ALASKA': 'AK',
  'ARIZONA': 'AZ',
  'ARKANSAS': 'AR',
  'CALIFORNIA': 'CA',
  'COLORADO': 'CO',
  'CONNECTICUT': 'CT',
  'DELAWARE': 'DE',
  'FLORIDA': 'FL',
  'GEORGIA': 'GA',
  'HAWAII': 'HI',
  'IDAHO': 'ID',
  'ILLINOIS': 'IL',
  'INDIANA': 'IN',
  'IOWA': 'IA',
  'KANSAS': 'KS',
  'KENTUCKY': 'KY',
  'LOUISIANA': 'LA',
  'MAINE': 'ME',
  'MARYLAND': 'MD',
  'MASSACHUSETTS': 'MA',
  'MICHIGAN': 'MI',
  'MINNESOTA': 'MN',
  'MISSISSIPPI': 'MS',
  'MISSOURI': 'MO',
  'MONTANA': 'MT',
  'NEBRASKA': 'NE',
  'NEVADA': 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  'OHIO': 'OH',
  'OKLAHOMA': 'OK',
  'OREGON': 'OR',
  'PENNSYLVANIA': 'PA',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  'TENNESSEE': 'TN',
  'TEXAS': 'TX',
  'UTAH': 'UT',
  'VERMONT': 'VT',
  'VIRGINIA': 'VA',
  'WASHINGTON': 'WA',
  'WEST VIRGINIA': 'WV',
  'WISCONSIN': 'WI',
  'WYOMING': 'WY'
};

const normalizeState = (state: string): string => {
  if (!state) return '';
  const upperState = state.toUpperCase();
  
  // If it's already an abbreviation, return it
  if (Object.values(STATE_ABBREVIATIONS).includes(upperState)) {
    return upperState;
  }
  
  // If it's a full name, return the abbreviation
  return STATE_ABBREVIATIONS[upperState] || upperState;
};

const calculateFoodFitScore = (influencer: Influencer, participatingLocations: Location[] = []): number => {
  let score = 0;
  
  // Location Score (0-50 points total)
  let bestLocationScore = 0;
  
  // Normalize influencer state once
  const normalizedInfluencerState = normalizeState(influencer.state);
  
  participatingLocations?.forEach((location: Location) => {
    let currentLocationScore = 0;
    
    // City Score (0-35 points)
    if (influencer.city?.toLowerCase() === location.city?.toLowerCase()) {
      currentLocationScore += 35;
    }
    
    // State Score (0-15 points)
    const normalizedLocationState = normalizeState(location.state);
    if (normalizedInfluencerState === normalizedLocationState) {
      currentLocationScore += 15;
    }
    
    bestLocationScore = Math.max(bestLocationScore, currentLocationScore);
  });

  // Engagement Rate Score (0-30 points)
  const engagementScore = Math.min(influencer.engagement_rate || 0, 10) * 3;
  
  // Follower Count Score (0-20 points)
  // Divide followers by 5000 and round up, max of 20 points
  const followerScore = Math.min(
    Math.ceil((influencer.follower_count || 0) / 4000),
    20
  );

  score = engagementScore + followerScore + bestLocationScore;

  // Debug logging
  console.log('Score Calculation:', {
    influencer: {
      city: influencer.city,
      state: influencer.state,
      normalizedState: normalizedInfluencerState,
      followerCount: influencer.follower_count,
      engagementRate: influencer.engagement_rate
    },
    scores: {
      location: bestLocationScore,    // 0-50 points
      engagement: engagementScore,    // 0-30 points
      follower: followerScore,        // 0-20 points (followers/5000 rounded up)
      total: score
    },
    locationMatches: participatingLocations.map(loc => ({
      city: loc.city,
      state: loc.state,
      normalizedState: normalizeState(loc.state),
      matches: {
        city: influencer.city?.toLowerCase() === loc.city?.toLowerCase(),
        state: normalizedInfluencerState === normalizeState(loc.state)
      }
    }))
  });

  return Math.min(Math.max(Math.round(score), 0), 100);
};

const calculateRealFollowerPercentage = (followerCount?: number, engagementRate?: number): number => {
  if (!followerCount || !engagementRate) return 0;
  
  // Convert engagement rate to percentage if it's in decimal form
  const engagementPercentage = engagementRate > 1 ? engagementRate : engagementRate * 100;
  
  // Base score starts at 92% (more optimistic baseline)
  let realScore = 92;

  // Engagement Rate Analysis
  if (engagementPercentage >= 8) {
    realScore += 8;  // Up to 100%
  } else if (engagementPercentage >= 5) {
    realScore += 5;  // Up to 97%
  } else if (engagementPercentage >= 3) {
    realScore += 2;  // Up to 94%
  } else if (engagementPercentage >= 1) {
    realScore -= 5;  // Down to 87%
  } else {
    realScore -= 12; // Down to 80%
  }

  // Follower Count Analysis
  if (followerCount >= 1000000) {
    realScore -= 7;  // 1M+ followers
  } else if (followerCount >= 500000) {
    realScore -= 5;  // 500K+ followers
  } else if (followerCount >= 100000) {
    realScore -= 3;  // 100K+ followers
  } else if (followerCount >= 10000) {
    realScore += 0;  // 10K+ followers
  } else {
    realScore += 3;  // Small accounts bonus
  }

  // Ensure score stays within 0-100 range
  return Math.min(Math.max(realScore, 0), 100);
};

const CircularProgress: React.FC<{ percentage: number, isScore?: boolean }> = ({ percentage, isScore }) => (
  <Tooltip 
    label={
      <VStack spacing={1} p={2}>
        {isScore ? (
          <>
            <Text color="white">FoodFit Score: {percentage}</Text>
            <Text fontSize="sm" color="white">
              A comprehensive score (0-100) that evaluates an influencer's fit for food-related collaborations based on engagement rates, follower count, and location relevance.
            </Text>
            <Text fontSize="sm" color="white">
              Score Breakdown:
              • Location: 50 points max
              • Engagement: 30 points max
              • Followers: 20 points max
            </Text>
          </>
        ) : (
          <>
            <Text color="white">Real Follower Estimate: {percentage}%</Text>
            <Text fontSize="sm" color="white">
              This score estimates the percentage of real followers based on a combination of follower count, engagement rate, and account activity patterns.
            </Text>
          </>
        )}
      </VStack>
    }
    placement="top"
    hasArrow
    bg="gray.600"
    color="white"
    borderRadius="md"
  >
    <Circle size="70px" position="relative">
      <Circle 
        size="70px" 
        borderWidth="5px"
        borderColor="gray.100"
      />
      <Circle
        size="70px"
        position="absolute"
        top={0}
        borderWidth="5px"
        borderColor="black"
        borderBottomColor="transparent"
        borderRightColor={percentage >= 75 ? "black" : "transparent"}
        borderTopColor={percentage >= 50 ? "black" : "transparent"}
        borderLeftColor={percentage >= 25 ? "black" : "transparent"}
        transform={`rotate(${(percentage * 3.6) - 90}deg)`}
        transition="all 0.5s ease-out"
      />
      <VStack 
        position="absolute" 
        width="full"
        spacing={0}
        top="50%"
        transform="translateY(-50%)"
      >
        <Text fontSize="md" fontWeight="bold">
          {isScore ? percentage : `${percentage}%`}
        </Text>
        <Text fontSize="xs" color="gray.600">
          {isScore ? 'Score' : 'Real'}
        </Text>
      </VStack>
    </Circle>
  </Tooltip>
);

// Helper function to interpolate between two hex colors
const interpolateColor = (color1: string, color2: string, factor: number): string => {
  // Updated Chakra colors with more pastel-like shades
  const chakraColors = {
    "red.500": "#FF8080",    // Lighter, more pastel red
    "orange.500": "#FFB088", // Softer orange
    "yellow.500": "#FFE199", // Softer yellow
    "green.400": "#98D8AA",  // Light pastel green
    "green.500": "#85C88A",  // Medium pastel green
    "green.700": "#69B578"   // Darker but still pastel green
  };

  const hex1 = chakraColors[color1 as keyof typeof chakraColors];
  const hex2 = chakraColors[color2 as keyof typeof chakraColors];

  // Convert hex to RGB
  const r1 = parseInt(hex1.slice(1, 3), 16);
  const g1 = parseInt(hex1.slice(3, 5), 16);
  const b1 = parseInt(hex1.slice(5, 7), 16);
  
  const r2 = parseInt(hex2.slice(1, 3), 16);
  const g2 = parseInt(hex2.slice(3, 5), 16);
  const b2 = parseInt(hex2.slice(5, 7), 16);

  // Interpolate
  const r = Math.round(r1 + (r2 - r1) * factor);
  const g = Math.round(g1 + (g2 - g1) * factor);
  const b = Math.round(b1 + (b2 - b1) * factor);

  // Convert back to hex
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const getFoodFitScoreColor = (score: number): string => {
  const colorStops = [
    { score: 0, color: "red.500" },     // Pastel red
    { score: 30, color: "orange.500" }, // Pastel orange
    { score: 50, color: "yellow.500" }, // Pastel yellow
    { score: 70, color: "green.400" },  // Light pastel green
    { score: 85, color: "green.500" },  // Medium pastel green
    { score: 100, color: "green.700" }  // Darker pastel green
  ];

  // Find the appropriate color stops
  for (let i = 1; i < colorStops.length; i++) {
    if (score <= colorStops[i].score) {
      const prevStop = colorStops[i - 1];
      const currentStop = colorStops[i];
      const percentage = (score - prevStop.score) / (currentStop.score - prevStop.score);
      
      return interpolateColor(prevStop.color, currentStop.color, percentage);
    }
  }
  
  return colorStops[colorStops.length - 1].color;
};

const MetricSection: React.FC<{
  platform: 'instagram' | 'tiktok' | 'foodfit';
  followers?: string;
  realPercentage?: number;
  engagementRate?: string;
  score?: number;
  showDivider?: boolean;
}> = ({ 
  platform, 
  followers, 
  realPercentage, 
  engagementRate,
  score,
  showDivider = true,
}) => (
  <Box flex={1} px={6}>
    <HStack spacing={4} align="flex-start">
      {platform === 'foodfit' && (
        <Box>
          <Tooltip 
            label={
              <VStack spacing={1} p={2}>
                <Text color="white">FoodFit Score: {score}</Text>
                <Text fontSize="sm" color="white">
                  A comprehensive score (0-100) that evaluates an influencer's fit for your brand's campaign based on engagement rates, follower count, and location relevance and content type.
                </Text>
                
              </VStack>
            }
            placement="top"
            hasArrow
            bg="gray.600"
            color="white"
            borderRadius="md"
          >
            <Circle
              size="65px"
              bg={getFoodFitScoreColor(score || 0)}
              color="black"  // Changed from white to black
              fontSize="2xl"
              fontWeight="extrabold"
              position="relative"
              cursor="pointer"
            >
              {score}
            </Circle>
          </Tooltip>
        </Box>
      )}
      {platform !== 'foodfit' && <CircularProgress percentage={realPercentage || 0} isScore={false} />}
      
      <VStack spacing={1} align="flex-start" flex={1}>
        <HStack spacing={2}>
          {(platform === 'instagram' || platform === 'tiktok') && (
            <Icon 
              as={platform === 'instagram' ? FaInstagram : FaTiktok} 
              boxSize={4}
              color={platform === 'instagram' ? 'black.500' : 'black'}
            />
          )}
          <Text fontWeight="semibold" fontSize="sm">
            {platform === 'instagram' ? 'Instagram' : platform === 'tiktok' ? 'TikTok' : 'FoodFit Score'}
          </Text>
        </HStack>

        {platform !== 'foodfit' && (
          <>
            <Text fontSize="md" fontWeight="semibold">
              {followers} Followers
            </Text>
            <Text fontSize="sm" color="gray.600">
              {engagementRate} Engagement Rate
            </Text>
          </>
        )}
      </VStack>
    </HStack>
    {showDivider && (
      <Box position="absolute" right={0} top="50%" transform="translateY(-50%)">
        <Divider orientation="vertical" height="70px" borderColor="gray.200" />
      </Box>
    )}
  </Box>
);

const InfluencerMetrics: React.FC<InfluencerMetricsProps> = ({ influencer }) => {
  const [participatingLocations, setParticipatingLocations] = useState<Location[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const clientID = queryParams.get("clientID");

  useEffect(() => {
    const fetchLocations = async () => {
      if (clientID) {
        try {
          const locations = await getClientFoodfluenceLocations(clientID);
          setParticipatingLocations(locations);
        } catch (error) {
          console.error("Error fetching locations:", error);
          setParticipatingLocations([]);
        }
      }
    };

    fetchLocations();
  }, [clientID]);

  const foodFitScore = React.useMemo(() => 
    calculateFoodFitScore(influencer, participatingLocations), 
    [influencer, participatingLocations]
  );

  const formatEngagementRate = (rate: number | undefined): string => {
    if (!rate) return 'N/A';
    return `${(rate).toFixed(1)}%`;
  };

  return (
    <Box 
      borderRadius="xl" 
      borderWidth="1px" 
      borderColor="gray.200"
      bg="white"
      py={6}
      px={4}
      shadow="sm"
      _hover={{ shadow: 'md' }}
      transition="all 0.2s"
      width="100%"
    >
      <HStack spacing={0} align="flex-start" position="relative">
        <MetricSection 
          platform="instagram"
          followers={!influencer.follower_count ? 'N/A' : formatCount(influencer.follower_count)}
          realPercentage={calculateRealFollowerPercentage(
            influencer.follower_count,
            influencer.engagement_rate
          )}
          engagementRate={formatEngagementRate(influencer.engagement_rate)}
        />
        <Divider orientation="vertical" height="70px" borderColor="gray.600" borderWidth="1px" />
        <MetricSection 
          platform="tiktok"
          followers={!influencer.tiktokFollowers ? 'N/A' : formatCount(influencer.tiktokFollowers)}
          realPercentage={calculateRealFollowerPercentage(
            influencer.tiktokFollowers,
            influencer.tiktokEngagementRate
          )}
          engagementRate={formatEngagementRate(influencer.tiktokEngagementRate)}
        />
        <Divider orientation="vertical" height="70px" borderColor="gray.600" borderWidth="1px" />
        <MetricSection
          platform="foodfit"
          score={foodFitScore}
          showDivider={false}
        />
      </HStack>
    </Box>
  );
};

export default InfluencerMetrics;
