import React, { useState } from 'react';
import { Box, Button, Text, Flex, Container, IconButton } from '@chakra-ui/react';
import { FiMapPin, FiSearch } from 'react-icons/fi';
import Analytics from './analytics';
import CreateCampaignModal from './CreateCampaignModal';
import SettingsModal from './SettingsModal';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface WelcomeBannerProps {
  clientID: string;
  accountName: string;
}

const WelcomeBanner: React.FC<WelcomeBannerProps> = ({ clientID, accountName}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchClick = () => {
    history.push(`${path}/search?clientID=${clientID}`);
  };

  return (
    <Box position="relative" width="100%" mb={24}>
      <Container maxW="container.2xl" px={{ base: 6, md: 8 }}>
        <Box
          bg="black"
          color="white"
          pt={4}
          pb={14}
          borderRadius="2xl"
          position="relative"
          zIndex={1}
          width="100%"
          overflow="hidden"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ base: 'column', md: 'row' }}
            gap={4}
            mb={2}
            px={{ base: 6, md: 8 }}
          >
            <Box>
              <Text 
                fontSize="sm"
                color="gray.400"
                mb={0.5}
                letterSpacing="0.5px"
              >
                Welcome back
              </Text>
              <Text 
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight="700"
                bgGradient="linear(to-r, #CDFBB1, #94F0B3)"
                bgClip="text"
                letterSpacing="-1px"
              >
                {accountName}
              </Text>
            </Box>
            <Flex gap={4} alignItems="center">
              <IconButton
                aria-label="Settings"
                icon={<FiMapPin />}
                bg="white"
                color="black"
                _hover={{ 
                  bg: "gray.100",
                  transform: "translateY(-2px)",
                  boxShadow: "lg"
                }}
                onClick={() => setIsSettingsOpen(true)}
                size="lg"
                borderRadius="xl"
                transition="all 0.2s"
              />
              <IconButton
                aria-label="Search"
                icon={<FiSearch />}
                bg="white"
                color="black"
                _hover={{ 
                  bg: "gray.100",
                  transform: "translateY(-2px)",
                  boxShadow: "lg"
                }}
                onClick={handleSearchClick}
                size="lg"
                borderRadius="xl"
                transition="all 0.2s"
              />
              <Button
                bg="white"
                color="black"
                _hover={{ 
                  bg: "gray.100",
                  transform: "translateY(-2px)",
                  boxShadow: "lg"
                }}
                onClick={handleOpenModal}
                size="lg"
                fontSize="md"
                px={6}
                height="44px"
                borderRadius="xl"
                transition="all 0.2s"
                fontWeight="600"
              >
                Start New Campaign
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Container>

      <Container 
        maxW="container.2xl" 
        position="absolute" 
        left="50%" 
        transform="translateX(-50%)"
        bottom="-65px"
        px={{ base: 6, md: 8 }}
        zIndex={2}
      >
        <Box 
          width="95%" 
          mx="auto"
        >
          <Analytics />
        </Box>
      </Container>

      <CreateCampaignModal clientID={clientID} isOpen={isModalOpen} onClose={handleCloseModal} />
      <SettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} clientID={clientID} />
    </Box>
  );
};

export default WelcomeBanner;