import React, { useContext, useState, useEffect } from "react";

import {
    Box,
    Button,
    Flex,
    Text,
    Spinner,
    SimpleGrid,
    useToast,
  } from "@chakra-ui/react";

import QrCodeButton from '../accounts/pages/locations/components/QrCodeButton';

import { AccountsDataContext } from "contexts/accountsDataContext";
import Dropdown from "./components/dropDown";
import { TouchpointsDataContext } from "../../../contexts/touchpointsDataContext";

import DMBotSwitch from './components/DMBotSwitch';
import TouchpointsTable from "./components/TouchpointsTable";
import CustomTextMessagesPopup from "./components/CustomTextMessagesPopUp";


export default function Touchpoints() {
    const touchpointsDataContext = useContext(TouchpointsDataContext);
    const [filteredClientID, setfilteredClientID] = useState("");
    const [selectedAccountName, setSelectedAccountName] = useState<string>('');

    const accountsData = useContext(AccountsDataContext);

    const [isDMBotEnabled, setIsDMBotEnabled] = useState(false)
    
    let clientAccounts = []
    for (let account in accountsData) {
        if (account && accountsData[account]) {
            clientAccounts.push({
                ...accountsData[account]
            })
        }
    }
    
    const filteredTouchpointsData = touchpointsDataContext[filteredClientID] ?? {};
    
    const handleAccountNameChange = (name: string) => {
      setSelectedAccountName(name);
    };
   
    return (
    <Box
      overflow="auto"
      backgroundColor="white"
      borderRadius="10px"
      height="calc(100vh - 135px)" //'750px'
      position="relative" // Make this box a reference point for the pseudo-element
      
    >

    <Box pt="25px"  left={0} width="100%" height="100%">
          <Flex m="20px" pb="25" justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Text

                fontWeight="bold"
                fontSize="25px"
                mb="0px"
              >
                Touchpoints
              </Text>
              <QrCodeButton clientID={filteredClientID} touchpointID={""} locationID={""}/>
            </Flex>
          <Box>
            <Flex gap="5px">
              {/* <DMBotSwitch
                isDMBotEnabled={isDMBotEnabled}
                toggleSwitch={toggleSwitch}
              /> */}

              {accountsData && Object.keys(accountsData)?.length > 0 && (
                <Dropdown
                  filteredClientId={filteredClientID}
                  clientAccounts={clientAccounts}
                  setFilteredClientId={setfilteredClientID}
                  setSelectedAccountName={handleAccountNameChange}
                  selectedAccountName={selectedAccountName}
                />
              )}  
            </Flex>
            </Box> 
            
          </Flex>
          <Flex m="20px" flexDirection="column" gap="50px">
           <TouchpointsTable clientID={filteredClientID} touchpoints={filteredTouchpointsData}/>
          {accountsData[filteredClientID]?.isWhiteLabeled?(
            <CustomTextMessagesPopup accountName={selectedAccountName} whiteLabelDomain={accountsData[filteredClientID]?.whiteLabelDomain}
             whiteLabeledKit={accountsData[filteredClientID]?.whiteLabeledKit}/>):(<></>)
          }
           </Flex>
         
        </Box>

         </Box>

    
    )

}
