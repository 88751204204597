import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import CollabDetailsView from './CollabDetailsView';
import { CollaborationsData, InfluencerCampaign } from '../types';

interface CollabInviteViewProps {
  isOpen: boolean;
  onClose: () => void;
  campaign: InfluencerCampaign;
  collab: CollaborationsData;
}

const CollabInviteView: React.FC<CollabInviteViewProps> = ({
  isOpen,
  onClose,
  campaign,
  collab,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"}>
        <ModalHeader>Campaign Details</ModalHeader>
        <ModalBody>
          <CollabDetailsView
            campaign={campaign}
            deliverableCounts={collab.deliverableCounts}
            cash={collab.cash?.toString() || '0'}
            credit={collab.credit?.toString() || '0'}
            isReadOnly={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CollabInviteView;