import React, { useState, useEffect, useContext } from 'react';
import { Box, Flex, Text, Spinner, Button, Image, Center } from '@chakra-ui/react';
import { FaChevronRight, FaPlay } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { isVideo } from '../utils/contentUtils';
import FullscreenModal from './FullscreenModal';
import { CollaborationsData, DeliverableLink } from '../types';

const RecentPosts: React.FC = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const collaborations = useContext(CollabsDataContext);
  const [recentCollabs, setRecentCollabs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const influencers = useContext(InfluencersDataContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<{ link: string, isVideo: boolean, alt: string } | null>(null);

  function isContextLoaded(
    collaborations: Record<string, any>,
    influencers: Record<string, any>,
  ): boolean {
    return (
      Object.keys(collaborations).length > 0 &&
      Object.keys(influencers).length > 0
    );
  }

  useEffect(() => {
    const fetchRecentCollabs = async () => {
      try {
        if (!isContextLoaded(collaborations, influencers)) {
          setIsLoading(false);
          return;
        }

        const newRecentCollabs: any[] = [];

        Object.values(collaborations).forEach((collab: CollaborationsData) => {
          if (
            collab.deliverableLinks
          ) {
            Object.entries(collab.deliverableLinks).forEach(([key, linkArray]: any) => {
              if (Array.isArray(linkArray)) {
                linkArray.forEach((linkObj: DeliverableLink, index: number) => {
                  
                  if(linkObj.status === "approved") {
                    const influencer = influencers[collab.influencerID];
                    if (linkObj.verifiedUGCLink) {
                      const recentCollabItem = {
                        collabId: collab.id,
                        link: linkObj.verifiedUGCLink,
                        postUrl: linkObj.userLink,
                        influencerName: influencer?.instagramHandle,
                        influencerProfilePic: influencer?.profile_pic_url,
                        collabDate: linkObj?.verifiedAt,
                        contentType: key,
                        contentIndex: index
                      };
                      newRecentCollabs.push(recentCollabItem);
                    }
                  }
                });
              }
            });
          }
        });
        newRecentCollabs.sort((a, b) => {return b.collabDate.seconds - a.collabDate.seconds; });
        newRecentCollabs.filter(
          (item, idx, self) =>
            idx === self.findIndex((t) => t.postUrl === item.postUrl && t.collabId === item.collabId)
        );
        setRecentCollabs(newRecentCollabs);
        setIsLoading(false);
      } catch (error) {
        console.error("Error processing recent collabs:", error);
        setIsLoading(false);
      } 
    };

    fetchRecentCollabs();
  }, [collaborations, influencers]);

  const handleViewAllClick = () => {
    history.push(`${path}/foodfluence-ugc-library`);
  };

  const handlePostClick = (link: string, isVideo: boolean, alt: string) => {
    setSelectedContent({ link, isVideo, alt });
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <>
      <Box
        bg="white"
        borderRadius="xl"
        border="1px solid"
        borderColor="gray.300"
        width="100%"
        height="300px"
        overflow="hidden"
      >
        <Flex justify="space-between" align="center" px={4} py={2} gap={4}>
          <Text fontSize="lg" fontWeight="600" mt="2" ml="3px">
            Recent Posts
          </Text>
          {recentCollabs.length > 0 && (
            <Button
              onClick={handleViewAllClick}
              rightIcon={<FaChevronRight />}
              color="gray.600"
              fontSize="sm"
            >
              View All
            </Button>
          )}
        </Flex>

        {recentCollabs.length > 0 ? (
          <Flex wrap="nowrap" overflowX="auto" px={4} py={2} gap={4} height="calc(100% - 50px)" sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
            {recentCollabs.map(collab => (
              <Box
                key={`${collab.collabId}_${collab.contentType}_${collab.contentIndex}`}
                borderRadius="md"
                _hover={{ borderColor: 'black' }}
                textAlign="left"
                width="200px"
                height="225px"
                flexShrink={0}
                flexDirection="column"
                gap={2}
                position="relative"
                p={2}
                border="1px solid"
                borderColor="gray.200"
                onClick={() => handlePostClick(collab.link, isVideo(collab.link), `Image of ${collab.influencerName}`)}
                cursor="pointer"
              >
                <Box 
                  position="absolute" 
                  top="8px" 
                  left="8px" 
                  bg="black" 
                  color="white" 
                  borderRadius="md" 
                  p={1}
                  zIndex={1}
                >
                </Box>
                <Box backgroundColor={'#000'} position={'relative'} pt="100%" borderRadius="md" overflow={"hidden"}>
                  {isVideo(collab.link) ? (
                    <>
                      <video
                        width="100%"
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center"
                        }}
                      >
                        <source src={collab.link} type="video/mp4" />
                        An error has occurred loading the video.
                      </video>
                      <Center
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        zIndex={2}
                        pointerEvents="none"
                      >
                        <FaPlay color="white" size="2em" />
                      </Center>
                    </>
                  ) : (
                    <Image
                      src={collab.link}
                      alt={`Image of ${collab.influencerName}`}
                      objectFit="cover"
                      objectPosition="center"
                      borderRadius="md"
                      position={'absolute'}
                      top={'0px'}
                      left={'0px'}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </Box>
                <Flex mt={2} alignItems="center" >
                  <Text fontWeight="510" ml="3px">@{collab.influencerName} </Text>
                </Flex>
              </Box>
            ))}
          </Flex>
        ) : (
          <Flex 
            height="calc(100% - 50px)" 
            alignItems="center"
            justifyContent="center"
            px={4} 
            py={2}
          >
            <Text fontSize="lg" color="gray.500" mb={8}>
              Once creators post, you can view all posted content here
            </Text>
          </Flex>
        )}
      </Box>

      {selectedContent && (
        <FullscreenModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          imageUrl={selectedContent.link}
          alt={selectedContent.alt}
          isVideo={selectedContent.isVideo}
        />
      )}
    </>
  );
};

export default RecentPosts;


