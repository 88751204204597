import React, { useContext, useState } from 'react';
import {
  Box,
  Text,
  Progress,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { InfluencerCampaign } from '../types';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';


interface ActiveCampaignsTableProps {
  clientId: string;
  onCampaignClick: (campaignId: string) => void;
}

type SortField = 'name' | 'date' | 'creators' | 'spend' | 'acceptance';
type SortDirection = 'asc' | 'desc';

const inProgressStatuses = [
  "pendingRedemption",
  "collabRedeemed",
  "pendingSubmission",
];

const ActiveCampaignsTable: React.FC<ActiveCampaignsTableProps> = ({clientId, onCampaignClick}) => {

  const influencerCampaigns = useContext(InfluencerCampaignsContext);
  const collabs = useContext(CollabsDataContext);
  const influencers = useContext(InfluencersDataContext);

  const [sortField, setSortField] = useState<SortField>('creators');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const getCampaignCollaborations = (campaignId: string) => {
    return Object.values(collabs).filter(collab => collab.influencerCampaignID === campaignId);
  };

  const getActiveCreators = (influencerCampaignId: string) => {
    const collaborations = getCampaignCollaborations(influencerCampaignId);
    if (!collaborations) return 0;

    return collaborations.length;
  };

  const calculateResponseRate = (influencerCampaignId: string) => {
    const collaborations = getCampaignCollaborations(influencerCampaignId);
    if (!collaborations || collaborations.length === 0) return 0;

    const respondedCollabs = collaborations.filter(
      collab => [...inProgressStatuses, "collabCompleted"].includes(collab.status)
    );

    return Math.round((respondedCollabs.length / collaborations.length) * 100);
  };

  const getTotalSpend = (campaignId: string) => {
    const collaborations = getCampaignCollaborations(campaignId);
    if (!collaborations) return { completed: 0, projected: 0 };

    const completed = collaborations
      .filter(collab => collab.status === "collabCompleted")
      .reduce((total, collab) => {
        return total + (collab.cash || 0) + (collab.credit || 0);
      }, 0);

    const projected = collaborations
      .filter(collab => 
        collab.status === "collabCompleted" || 
        inProgressStatuses.includes(collab.status)
      )
      .reduce((total, collab) => {
        return total + (collab.cash || 0) + (collab.credit || 0);
      }, 0);

    return { completed, projected };
  };

  const handleRowClick = (campaign: InfluencerCampaign) => {
    onCampaignClick(campaign.id);
  };

  const getCampaignInfluencers = (campaignId: string) => {
    const collaborations = getCampaignCollaborations(campaignId);
    if (!collaborations) return [];

    return collaborations.map(collab => {
      const influencer = influencers[collab.influencerID];
      return {
        name: influencer?.instagramHandle || 'Unknown',
        image: influencer?.profile_pic_url
      };
    });
  };

  const getCreatorCount = (campaignId: string) => {
    if (!collabs) return 0;
    return Object.values(collabs).filter(
      collab => collab.influencerCampaignID === campaignId
    ).length;
  };

  const formatDate = (date: Date | null) => {
    if (!date) {
      // Default to November 1, 2024
      return formatDate(new Date('2024-11-01'));
    }
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const sortedCampaigns = Object.values(influencerCampaigns || {})
    .sort((a, b) => {
      switch (sortField) {
        case 'name':
          return sortDirection === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        
        case 'date':
          const defaultDate = new Date('2024-11-01');
          const aDate = a.startDate?.toDate() || defaultDate;
          const bDate = b.startDate?.toDate() || defaultDate;
          return sortDirection === 'asc'
            ? aDate.getTime() - bDate.getTime()
            : bDate.getTime() - aDate.getTime();

        case 'creators':
          const aCreators = getCreatorCount(a.id);
          const bCreators = getCreatorCount(b.id);
          return sortDirection === 'asc' 
            ? aCreators - bCreators
            : bCreators - aCreators;
        
        case 'spend':
          const aSpend = getTotalSpend(a.id).projected;
          const bSpend = getTotalSpend(b.id).projected;
          return sortDirection === 'asc'
            ? aSpend - bSpend
            : bSpend - aSpend;
        
        case 'acceptance':
          const aRate = calculateResponseRate(a.id);
          const bRate = calculateResponseRate(b.id);
          return sortDirection === 'asc'
            ? aRate - bRate
            : bRate - aRate;
        
        default:
          return 0;
      }
    });

  return (
    <Box 
      bg="white" 
      borderRadius="xl" 
      width="100%"
      mt={4}
      border="1px solid" 
      borderColor="gray.300"
      position="relative"
      zIndex={2}
      height="270px"
      display="flex"
      flexDirection="column"
    >
      <Text 
        fontSize="lg" 
        fontWeight="600" 
        mb={4}
        px={4}
        pt={2}
      >
        Active Campaigns
      </Text>
      
      <Box pb={4} overflowY="auto" flex="1">
        <Box
          bg="#F9F9F9"
          position="sticky"
          top={0}
          zIndex={3}
          width="100%"
        >
          <Flex 
            py={2}
            px={4}
            mb={1}
          >
            <Text 
              flex="2" 
              color="gray.600" 
              fontSize="sm" 
              fontWeight="500" 
              textAlign="left"
              cursor="pointer"
              onClick={() => handleSort('name')}
              _hover={{ color: 'gray.800' }}
            >
              CAMPAIGN NAME {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </Text>
            <Text 
              flex="1" 
              color="gray.600" 
              fontSize="sm" 
              fontWeight="500" 
              textAlign="left"
              cursor="pointer"
              onClick={() => handleSort('date')}
              _hover={{ color: 'gray.800' }}
            >
              DATE CREATED {sortField === 'date' && (sortDirection === 'asc' ? '↑' : '↓')}
            </Text>
            <Text 
              flex="1" 
              color="gray.600" 
              fontSize="sm" 
              fontWeight="500" 
              textAlign="left"
              cursor="pointer"
              onClick={() => handleSort('creators')}
              _hover={{ color: 'gray.800' }}
            >
              CREATORS {sortField === 'creators' && (sortDirection === 'asc' ? '↑' : '↓')}
            </Text>
            <Text 
              flex="1" 
              color="gray.600" 
              fontSize="sm" 
              fontWeight="500" 
              textAlign="left"
              cursor="pointer"
              onClick={() => handleSort('spend')}
              _hover={{ color: 'gray.800' }}
            >
              TOTAL SPEND {sortField === 'spend' && (sortDirection === 'asc' ? '↑' : '↓')}
            </Text>
            <Text 
              flex="1" 
              color="gray.600" 
              fontSize="sm" 
              fontWeight="500" 
              textAlign="left"
              cursor="pointer"
              onClick={() => handleSort('acceptance')}
              _hover={{ color: 'gray.800' }}
            >
              ACCEPTANCE RATE {sortField === 'acceptance' && (sortDirection === 'asc' ? '↑' : '↓')}
            </Text>
          </Flex>
        </Box>

        <Box px={4}>
          {sortedCampaigns.length > 0 ? sortedCampaigns.map((influencerCampaign) => (
            <Flex 
              key={influencerCampaign.id}
              py={3}
              alignItems="center"
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              borderRadius="lg"
              onClick={() => handleRowClick(influencerCampaign)}
            >
              <Text flex="2" fontWeight="500" textAlign="left">
                {influencerCampaign.name}
              </Text>
              <Text flex="1" fontWeight="500" textAlign="left">
                {formatDate(influencerCampaign.startDate?.toDate() || null)}
              </Text>
              <Flex flex="1" alignItems="center">
                <Flex position="relative">
                  {getCampaignInfluencers(influencerCampaign.id)
                    .slice(0, 3)
                    .map((influencer, index) => (
                      <Avatar
                        key={index}
                        name={influencer.name}
                        src={influencer.image}
                        size="sm"
                        position="relative"
                        marginLeft={index === 0 ? "0" : "-3"}
                        zIndex={1}
                      />
                    ))}
                  {getCampaignInfluencers(influencerCampaign.id).length > 3 && (
                    <Avatar
                      size="sm"
                      bg="green.400"
                      color="white"
                      position="relative"
                      marginLeft="-3"
                      zIndex={2}
                      icon={<Text>+{getCampaignInfluencers(influencerCampaign.id).length - 3}</Text>}
                    />
                  )}
                </Flex>
              </Flex>
              <Flex flex="1" alignItems="center">
                <Box
                  px={3}
                  py={1.5}
                  borderRadius="full"
                  bg="blackAlpha.50"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text 
                    fontSize="sm" 
                    fontWeight="600" 
                    color="gray.700"
                  >
                    ${getTotalSpend(influencerCampaign.id).completed.toLocaleString()}
                    <Text 
                      as="span" 
                      color="gray.500" 
                      fontWeight="500"
                    >
                      {" → "}${getTotalSpend(influencerCampaign.id).projected.toLocaleString()}
                    </Text>
                  </Text>
                </Box>
              </Flex>
              <Flex flex="1" alignItems="center" gap={3} justifyContent="flex-start">
                <Text>{calculateResponseRate(influencerCampaign.id)}%</Text>
                <Progress 
                  value={calculateResponseRate(influencerCampaign.id)} 
                  size="sm" 
                  colorScheme="green"
                  width="100px"
                  borderRadius="full"
                  bg="gray.200"
                />
              </Flex>
            </Flex>
          )) : (
            <Flex 
              direction="column" 
              align="flex-start" 
              py={6}
              color="gray.500"
            >
              <Text>Active campaigns will show up here</Text>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveCampaignsTable;