import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Divider,
} from '@chakra-ui/react';
import CollabDetailsView from './CollabDetailsView';
import { CollaborationsData, InfluencerCampaign } from '../types';

interface CanceledCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaign: InfluencerCampaign;
  collab: CollaborationsData;
}

const CanceledCollabModal: React.FC<CanceledCollabModalProps> = ({
  isOpen,
  onClose,
  campaign,
  collab,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"} border="2px solid #FF6B6B">
        <ModalHeader>Canceled Campaign Details</ModalHeader>
        <ModalBody>
          <CollabDetailsView
            campaign={campaign}
            deliverableCounts={collab.deliverableCounts}
            cash={collab.cash?.toString() || '0'}
            credit={collab.credit?.toString() || '0'}
            isReadOnly={true}
          />

          <Divider my={4} />
          
          <Box>
            <Text fontWeight="bold" fontSize="lg" mb={2}>Cancel Reason:</Text>
            <Box 
              p={4} 
              bg="red.50" 
              borderRadius="md"
              border="1px solid"
              borderColor="red.200"
            >
              <Text color="red.700">
                {collab?.influencerCancelReason || 'No reason provided'}
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CanceledCollabModal;
