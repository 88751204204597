import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure, Box, Text } from '@chakra-ui/react';
import { CollaborationsData, DeliverableLink, Influencer } from '../../types';
import { getAllCompletedDeliverables, getCompletedDeliverables } from '../../utils/collabsUtils';
import MediaGrid from './MediaGrid';
import ExpandedMediaModal from './ExpandedMediaModal';
import InfluencerInfo from './InfluencerInfo';
import { refreshPostMetrics } from 'services/flaskService';

interface CollabContentModalProps {
  isOpen: boolean;
  onClose: () => void;
  collab: CollaborationsData;
  influencer: Influencer;
}

const CollabContentModal: React.FC<CollabContentModalProps> = ({ isOpen, onClose, collab, influencer }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState<DeliverableLink | null>(null);
  const { isOpen: isExpandedViewOpen, onOpen: onExpandedViewOpen, onClose: onExpandedViewClose } = useDisclosure();
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isExpandedViewOpen && videoRef.current && selectedVideo) {
      videoRef.current.play().catch(error => console.error("Error playing video:", error));
    }
  }, [isExpandedViewOpen, selectedVideo]);

  useEffect(() => {
    if (isOpen) {
      refreshPostMetrics([collab.id]);
    }
  }, [isOpen]);

  if (!collab || !collab.deliverableLinks) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text>No data available for this collaboration.</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  const isVideo = (url: string) => url.includes('.mp4') || url.includes('.mov') || url.includes('.avi');

  const handleMediaClick = (media: DeliverableLink) => {
    setSelectedMedia(media);
    onExpandedViewOpen();
  };

  const handlePlayPause = (e: React.MouseEvent, mediaUrl: string) => {
    e.stopPropagation();
    setSelectedVideo(mediaUrl);
    onExpandedViewOpen();
  };

  const handleExpandedViewClose = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setSelectedVideo(null);
    setSelectedMedia(null);
    onExpandedViewClose();
  };

  const allMedia = getAllCompletedDeliverables(collab);

  const instagramPosts = getCompletedDeliverables(collab, 'instagramPosts');
  const instagramReels = getCompletedDeliverables(collab, 'instagramReels');
  const instagramStories = getCompletedDeliverables(collab, 'instagramStories');
  const tikToks = getCompletedDeliverables(collab, 'tiktoks');

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxW="60vw" maxH="90vh" border="2px solid #B8E79E">
          <ModalCloseButton />
          <ModalBody pt={16} pb={12} px={16}>
            <InfluencerInfo influencer={influencer} />
            {allMedia.length > 0 ? (
              <Tabs variant="line" colorScheme="blue" onChange={(index) => setTabIndex(index)}>
                <TabList mb="1.5em">
                  {(allMedia.length > 0) && <Tab fontWeight="semibold">All</Tab>}
                  {tikToks.length > 0 && <Tab fontWeight="semibold">TikToks</Tab>}
                  {instagramReels.length > 0 && <Tab fontWeight="semibold">Instagram Reels</Tab>}
                  {instagramStories.length > 0 && <Tab fontWeight="semibold">Instagram Stories</Tab>}
                  {instagramPosts.length > 0 && <Tab fontWeight="semibold">Instagram Posts</Tab>}
                </TabList>
                <TabPanels>
                  {(allMedia.length > 0) && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        <MediaGrid mediaArray={allMedia} onMediaClick={handleMediaClick} onPlayPause={handlePlayPause} />
                      </Box>
                    </TabPanel>
                  )}
                  {tikToks.length > 0 && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        <MediaGrid mediaArray={tikToks} onMediaClick={handleMediaClick} onPlayPause={handlePlayPause} />
                      </Box>
                    </TabPanel>
                  )}
                  {instagramReels.length > 0 && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        <MediaGrid mediaArray={instagramReels} onMediaClick={handleMediaClick} onPlayPause={handlePlayPause} />
                      </Box>
                    </TabPanel>
                  )}
                  {instagramStories.length > 0 && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        <MediaGrid mediaArray={instagramStories} onMediaClick={handleMediaClick} onPlayPause={handlePlayPause} />
                      </Box>
                    </TabPanel>
                  )}
                  {instagramPosts.length > 0 && (
                    <TabPanel>
                      <Box height="45vh" overflowY="auto">
                        <MediaGrid mediaArray={instagramPosts} onMediaClick={handleMediaClick} onPlayPause={handlePlayPause} />
                      </Box>
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            ) : (
              <Text>No media available for this collaboration.</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <ExpandedMediaModal isOpen={isExpandedViewOpen} onClose={handleExpandedViewClose} selectedVideo={selectedVideo} selectedMedia={selectedMedia} isVideo={isVideo} />
    </>
  );
};

export default CollabContentModal;
