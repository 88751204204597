import React, { useEffect, useState, useContext } from 'react';
import { Box, SimpleGrid, Text, Spinner, Divider, Flex } from '@chakra-ui/react';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { formatCount, calculatePostMetrics } from 'views/admin/foodfluence/utils/metricsUtils';
import { filterCampaignCollabs } from 'views/admin/foodfluence/utils/collabsUtils';

interface PostMetrics {
  likes: number;
  comments: number;
  shares: number;
  views: number;
}

interface PerformanceSummaryProps {
  campaignId: string;
}

const PerformanceSummary: React.FC<PerformanceSummaryProps> = ({ campaignId }) => {
  const collabs = useContext(CollabsDataContext);
  const influencers = useContext(InfluencersDataContext);
  const [metrics, setMetrics] = useState<PostMetrics>({
    likes: 0,
    comments: 0,
    shares: 0,
    views: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const calculateMetrics = () => {
      if (!campaignId || !collabs) {
        setLoading(false);
        return;
      }

      try {
        const campaignCollabs = filterCampaignCollabs(collabs, campaignId);
        const metrics = calculatePostMetrics(campaignCollabs);
        setMetrics(metrics);
        setLoading(false);
      } catch (error) {
        console.error('Error calculating metrics:', error);
        setLoading(false);
      }
    };

    calculateMetrics();
  }, [campaignId, collabs]);

  return (
    <Box 
      border="2px solid"
      borderColor="gray.300"
      borderRadius="lg" 
      p={6}
      mb={8}
    >
      <Text fontSize="lg" fontWeight="semibold" mb={8}>
        Performance Summary
      </Text>

      <Flex align="flex-start" justify="space-between">
        <Box flex="1" pl={6}>
          <Text color="gray.600" fontSize="lg" mb={4}>Likes</Text>
          <Text fontSize="3xl" fontWeight="bold">
            {loading ? <Spinner /> : formatCount(metrics.likes)}
          </Text>
        </Box>

        <Divider orientation="vertical" height="100px" borderColor="gray.600" borderWidth="1px" />

        <Box flex="1" pl={6}>
          <Text color="gray.600" fontSize="lg" mb={4}>Comments</Text>
          <Text fontSize="3xl" fontWeight="bold">
            {loading ? <Spinner /> : formatCount(metrics.comments)}
          </Text>
        </Box>

        <Divider orientation="vertical" height="100px" borderColor="gray.600" borderWidth="1px" />

        <Box flex="1" pl={6}>
          <Text color="gray.600" fontSize="lg" mb={4}>Shares</Text>
          <Text fontSize="3xl" fontWeight="bold">
            {loading ? <Spinner /> : formatCount(metrics.shares)}
          </Text>
        </Box>

        <Divider orientation="vertical" height="100px" borderColor="gray.600" borderWidth="1px" />

        <Box flex="1" pl={6}>
          <Text color="gray.600" fontSize="lg" mb={4}>Views</Text>
          <Text fontSize="3xl" fontWeight="bold">
            {loading ? <Spinner /> : formatCount(metrics.views)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default PerformanceSummary;