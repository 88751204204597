import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Divider,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaMapMarkerAlt, FaClock, FaUser } from 'react-icons/fa';
import moment from 'moment';
import CollabDetailsView from './CollabDetailsView';
import { CollaborationsData, InfluencerCampaign, FoodfluenceLocation } from '../types';
import { FoodfluenceLocationsDataContext } from 'contexts/foodfluenceLocationsDataContext';
import { getTimezoneDateDisplay } from '../utils/dateUtils';

interface UpcomingInStoreCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaign: InfluencerCampaign;
  collab: CollaborationsData;
}

const UpcomingInStoreCollabModal: React.FC<UpcomingInStoreCollabModalProps> = ({
  isOpen,
  onClose,
  campaign,
  collab,
}) => {
  const foodfluenceLocationsData = useContext(FoodfluenceLocationsDataContext);
  const location = foodfluenceLocationsData[collab.selectedLocation];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxWidth={"800px"}>
        <ModalHeader>Upcoming Collab Details</ModalHeader>
        <ModalBody>
          <CollabDetailsView
            campaign={campaign}
            deliverableCounts={collab.deliverableCounts}
            cash={collab.cash?.toString() || '0'}
            credit={collab.credit?.toString() || '0'}
            isReadOnly={true}
          />
          
          <Divider my={4} />
          
          <Box>
            <Text fontWeight="bold" fontSize="lg" mb={4}>Collab Information:</Text>
            <Box 
              p={4} 
              bg="gray.50" 
              borderRadius="md"
              border="1px solid"
              borderColor="gray.200"
            >
              {collab.scheduledCollabDate && (
                <Flex align="center" mb={3}>
                  <Icon as={FaClock} color="gray.600" mr={2} />
                  <Text color="gray.700">
                    {getTimezoneDateDisplay(foodfluenceLocationsData[collab.selectedLocation], collab.scheduledCollabDate.toDate())}
                  </Text>
                </Flex>
              )}
              
              {location && (
                <Flex align="center" mb={3}>
                  <Icon as={FaMapMarkerAlt} color="gray.600" mr={2} />
                  <Text color="gray.700">
                    {location.fullAddress}
                  </Text>
                </Flex>
              )}

              {collab?.partySize && collab?.partySize !== null && (
                <Flex align="center">
                  <Icon as={FaUser} color="gray.600" mr={2} />
                  <Text color="gray.700">
                    Party Size: {collab?.partySize}
                  </Text>
                </Flex>
              )}

              {!collab.scheduledCollabDate && (
                <Text color="red.500" fontStyle="italic">
                  {campaign?.campaignType === 'shipToHome' 
                    ? 'Awaiting shipping information from influencer'
                    : 'Awaiting influencer scheduling'
                  }
                </Text>
              )}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpcomingInStoreCollabModal;