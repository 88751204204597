import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ModalCloseButton,
  Spinner,
  Text,
  Box,
  VStack
} from '@chakra-ui/react';
import { addTrackingNumber } from 'services/firebaseService';
import { notifyInfluencerTracking } from 'services/flaskService';
import { Influencer, InfluencerCampaign, CollaborationsData } from '../types';
import ShipmentInfoView from './ShipmentInfoView';

interface UpdateTrackingModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaign: InfluencerCampaign;
  influencer: Influencer;
  collab: CollaborationsData;
}

const UpdateTrackingModal: React.FC<UpdateTrackingModalProps> = ({
  isOpen,
  onClose,
  campaign,
  influencer,
  collab,
}) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!trackingNumber.trim() || !collab.id || isLoading) return;
    try {
      setIsLoading(true);
      await addTrackingNumber(collab.id, trackingNumber.trim());
      setTrackingNumber('');
      notifyInfluencerTracking(collab.id, [trackingNumber.trim()]);
    } catch (error) {
      console.error('Error updating tracking number:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} isCentered size="xl">
      <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(5px)" />
      <ModalContent bg="white" borderRadius="xl" boxShadow="xl" mx={4}>
        <ModalHeader color="black" borderBottom="1px" borderColor="gray.100" py={4}>
          Tracking Information
        </ModalHeader>
        <ModalCloseButton color="gray.500" _hover={{ color: "gray.700" }} />
        
        <ModalBody py={6}>
          <VStack spacing={6} align="stretch">
            <ShipmentInfoView
              campaign={campaign}
              influencer={influencer}
              collab={collab}
            />

            {collab?.trackingNumbers?.length > 0 && (
              <Box>
                <Text mb={3} fontWeight="medium" fontSize="sm" color="gray.600" textTransform="uppercase" letterSpacing="wide">
                  Existing Tracking Numbers
                </Text>
                <Box p={4} bg="gray.50" borderRadius="lg" border="1px" borderColor="gray.200">
                  <VStack spacing={2} align="stretch">
                    {collab?.trackingNumbers.map((number, index) => (
                      <Text key={index} fontSize="sm" color="gray.700" display="flex" alignItems="center">
                        <Box as="span" w={2} h={2} borderRadius="full" bg="green.500" mr={3} />
                        {number}
                      </Text>
                    ))}
                  </VStack>
                </Box>
              </Box>
            )}

            <Box>
              <Text mb={3} fontWeight="medium" fontSize="sm" color="gray.600" textTransform="uppercase" letterSpacing="wide">
                New Tracking Number
              </Text>
              <Input
                type="text"
                placeholder="Enter tracking number"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                size="lg"
                borderRadius="md"
                borderColor="gray.200"
                _hover={{ borderColor: "gray.300" }}
                _focus={{ borderColor: "black", boxShadow: "0 0 0 1px black" }}
                _placeholder={{ color: "gray.400" }}
              />
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.100" py={4} gap={3}>
          <Button 
            bg="black" 
            color="white" 
            px={8}
            py={6}
            onClick={handleSubmit}
            _hover={{ bg: "gray.800" }}
            _active={{ bg: "gray.900" }}
            isDisabled={isLoading || !trackingNumber.trim()}
            borderRadius="md"
            fontSize="md"
            fontWeight="medium"
          >
            {isLoading ? <Spinner size="sm" /> : "Add Tracking"}
          </Button>
          <Button 
            variant="ghost" 
            onClick={() => onClose()}
            color="gray.600"
            px={8}
            py={6}
            _hover={{ bg: "gray.50" }}
            _active={{ bg: "gray.100" }}
            isDisabled={isLoading}
            borderRadius="md"
            fontSize="md"
            fontWeight="medium"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTrackingModal;
