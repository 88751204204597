import React, { useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon,
  VStack,
  HStack,
  Center,
  Divider,
} from '@chakra-ui/react';
import InstagramProfilePic from '../../../components/InstagramProfilePic';
import { Influencer } from '../../../types';
import { FaInstagram, FaTiktok } from "react-icons/fa6";
import InfluencerMetrics from './influencerMetrics';
import { AccountsDataContext } from '../../../../../../contexts/accountsDataContext';

interface InfluencerScorecardProps {
  isOpen: boolean;
  onClose: () => void;
  influencer: Influencer | null;
}

const InfluencerScorecard: React.FC<InfluencerScorecardProps> = ({ 
  isOpen, 
  onClose, 
  influencer 
}) => {
  const { client } = useContext(AccountsDataContext);

  if (!influencer) return null;

  const handleInstagramClick = () => {
    window.open(`https://instagram.com/${influencer.instagramHandle}`, '_blank');
  };

  const handleTikTokClick = () => {
    window.open(`https://tiktok.com/@${influencer.tiktokHandle}`, '_blank');
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="4xl"
      blockScrollOnMount={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent 
        maxW="1000px"
        mx="auto"
        my="auto"
        borderRadius="xl"
      >
        <ModalHeader>
          <Center>
            <VStack spacing={3}>
              <InstagramProfilePic
                profile_pic_url={influencer.profile_pic_url}
                instagramHandle={influencer.instagramHandle}
                imageSize="120px"
              />
              <VStack spacing={1}>
                <HStack 
                  spacing={2} 
                  cursor="pointer" 
                  onClick={handleInstagramClick}
                  _hover={{ color: "gray.800" }}
                >
                  <Icon 
                    as={FaInstagram} 
                    color="black"
                    _hover={{ color: "gray.800" }}
                  />
                  <Text 
                    fontSize="lg" 
                    color="gray.600"
                    _hover={{ color: "gray.800" }}
                  >
                    @{influencer.instagramHandle}
                  </Text>
                </HStack>
                {influencer.tiktokHandle && influencer.tiktokHandle !== "" && (
                  <HStack 
                    spacing={2} 
                    cursor="pointer" 
                    onClick={handleTikTokClick}
                    _hover={{ color: "gray.800" }}
                  >
                    <Icon 
                      as={FaTiktok} 
                      color="black"
                      _hover={{ color: "gray.800" }}
                    />
                    <Text 
                      fontSize="lg" 
                      color="gray.600"
                      _hover={{ color: "gray.800" }}
                    >
                      @{influencer.tiktokHandle}
                    </Text>
                  </HStack>
                )}
                <Text fontSize="sm" color="gray.500">
                  {influencer.city && `${influencer.city}${influencer.state ? ', ' : ''}`}
                  {influencer.state}
                </Text>
              </VStack>
            </VStack>
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={6} align="stretch">
            <Divider />
            <InfluencerMetrics influencer={influencer} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InfluencerScorecard;
