import React, { useState } from 'react';
import { Flex, Text, Button, Checkbox, HStack, Box } from '@chakra-ui/react';
import InstagramProfilePic from '../../../components/InstagramProfilePic';
import { updateInfluencerBookmarkedByClient } from 'services/firebaseService';
import { FaInstagram, FaTiktok, FaStar } from "react-icons/fa6";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { Influencer } from '../../../types';
import InfluencerScorecard from './influencerScorecard';
import { formatCount } from 'views/admin/foodfluence/utils/metricsUtils';

interface InfluencersListProps {
  influencers: Influencer[];
  bookmarkedInfluencers: String[];
  clientID: string;
  creatingCampaign: boolean;
  handleInfluencerSelected: (influencerID: string) => void;
  selectedInfluencers: Set<String>;
  setSelectedTags: (tags: string[]) => void;
  selectedTags: string[];
}

const InfluencersList: React.FC<InfluencersListProps> = (props) => {
  const { influencers, bookmarkedInfluencers, clientID, creatingCampaign, handleInfluencerSelected, selectedInfluencers, setSelectedTags, selectedTags } = props;

  const [selectedInfluencer, setSelectedInfluencer] = useState<Influencer | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  function formatEngagementRate(rate: number): string {
    return `${(rate).toFixed(2)}%`;
  }

  const handleInfluencerClick = (influencer: Influencer) => {
    setSelectedInfluencer(influencer);
    setIsModalOpen(true);
  };

  return (
    <>
      {influencers.map((influencer) => (
        <Flex 
          key={influencer.id} 
          gap={4}
          mt={4}
          align="center"
        >
          {creatingCampaign && (
            <Box pl={3}>
              <Checkbox
                isChecked={selectedInfluencers.has(influencer.id)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleInfluencerSelected(influencer.id);
                }}
                colorScheme='blue'
                border={selectedInfluencers.has(influencer.id) ? "0px solid" : "1px solid"}
                borderRadius="sm"
                size='lg'
              />
            </Box>
          )}

          <Flex 
            flex={1}
            direction="row" 
            gap={6}
            align="center"
            onClick={() => handleInfluencerClick(influencer)}
            cursor="pointer"
            position="relative"
            bg="white"
            py={4}
            px={8}
            borderRadius="xl"
            border="1px solid"
            borderColor="gray.200"
            minHeight="100px"
            _hover={{ 
              bg: "gray.50",
              transform: "translateY(-2px)",
              boxShadow: "md",
              borderColor: "blue.200",
              "& .view-more": { 
                opacity: 1,
                transform: "translateY(0)",
              }
            }}
          >
            <InstagramProfilePic
              profile_pic_url={influencer.profile_pic_url}
              instagramHandle={influencer.instagramHandle}
              imageSize={"90px"}
            />

            <Flex direction="column" gap={3} flex={1}>
              <Flex justify="space-between" align="center" mb={-1}>
                <HStack spacing={3}>
                  <Text fontSize="lg" fontWeight="semibold">
                    @{influencer.instagramHandle}
                  </Text>
                  {influencer.featured && <FaStar color="#0080FE" size="20px" />}
                </HStack>
              </Flex>

              <HStack spacing={4} color="gray.600">
                {influencer.instagramHandle && (
                  <HStack spacing={2}>
                    <Button 
                      as="a" 
                      href={`https://instagram.com/${influencer.instagramHandle}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      variant="link" 
                      size="md"
                      p={0}
                    >
                      <FaInstagram size="18px" />
                    </Button>
                    <Text fontSize="md">
                      {formatCount(influencer.follower_count)} followers
                    </Text>
                  </HStack>
                )}
                {influencer.tiktokHandle && influencer.tiktokFollowers > 0 && (
                  <>
                    <Text fontSize="md">|</Text>
                    <HStack spacing={2}>
                      <Button 
                        as="a" 
                        href={`https://www.tiktok.com/@${influencer.tiktokHandle}`} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        variant="link" 
                        size="md"
                        p={0}
                      >
                        <FaTiktok size="18px" />
                      </Button>
                      <Text fontSize="md">
                        {formatCount(influencer.tiktokFollowers)} followers
                      </Text>
                    </HStack>
                  </>
                )}
                {influencer.state && influencer.state.toLowerCase() !== 'unknown' && (
                  <>
                    <Text fontSize="md">|</Text>
                    <Text fontSize="md">
                      {influencer.city && influencer.city.toLowerCase() !== 'unknown' && `${influencer.city}, `}{influencer.state}
                    </Text>
                  </>
                )}
                {(influencer.tiktokEngagementRate || influencer.engagement_rate) && (
                  <>
                    <Text fontSize="md">|</Text>
                    <Text fontSize="md">
                      {formatEngagementRate(Math.max(
                        influencer.tiktokEngagementRate || 0,
                        influencer.engagement_rate || 0
                      ))} Engagement Rate
                    </Text>
                  </>
                )}
              </HStack>

              <Box position="absolute" right={6} top="50%" transform="translateY(-50%)">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    updateInfluencerBookmarkedByClient(clientID, influencer.id);
                  }}
                  variant="ghost"
                  size="md"
                  fontSize="xl"
                  p={2}
                  minW="auto"
                  height="auto"
                >
                  {bookmarkedInfluencers && bookmarkedInfluencers.includes(influencer.id) ?
                    (<FaBookmark color="green" />) : (<FaRegBookmark />)}
                </Button>
              </Box>

              {influencer.tags && influencer.tags.length > 0 && (
                <Flex gap={2} flexWrap="wrap" mt={1}>
                  {influencer.tags.map((tag, index) => {
                    const tagColors = [
                      { bg: "blue.50", color: "blue.600" },
                      { bg: "green.50", color: "green.600" },
                      { bg: "purple.50", color: "purple.600" },
                      { bg: "pink.50", color: "pink.600" },
                      { bg: "orange.50", color: "orange.600" },
                      { bg: "teal.50", color: "teal.600" },
                    ];
                    
                    const colorIndex = index % tagColors.length;
                    const { bg, color } = tagColors[colorIndex];

                    return (
                      <Box
                        key={index}
                        bg={bg}
                        color={color}
                        px={2}
                        py={0.5}
                        borderRadius="md"
                        fontSize="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!selectedTags.includes(tag)) {
                            setSelectedTags([...selectedTags, tag]);
                          }
                        }}
                        cursor="pointer"
                        _hover={{ 
                          bg: bg.replace('50', '100'),
                          transform: "translateY(-1px)"
                        }}
                        transition="all 0.2s"
                      >
                        {tag}
                      </Box>
                    );
                  })}
                </Flex>
              )}
            </Flex>

            <Flex
              className="view-more"
              position="absolute"
              top={2}
              right={6}
              fontSize="md"
              color="blue.500"
              opacity={0}
              transform="translateY(-5px)"
              transition="all 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
              align="center"
              gap={2}
            >
              <Text fontWeight="medium">View Details</Text>
              <Text as="span" fontSize="xl">→</Text>
            </Flex>
          </Flex>
        </Flex>
      ))}

      <InfluencerScorecard
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        influencer={selectedInfluencer}
      />
    </>
  )
};

export default InfluencersList;