import React, { useContext, useEffect, useState } from 'react';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { FaPlayCircle, FaImage, FaCircle, FaInstagram, FaTiktok } from 'react-icons/fa';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { ResponsivePie } from '@nivo/pie';
import { DeliverableType } from '../../../../../types';
import { formatCount, calculatePlatformMetrics } from 'views/admin/foodfluence/utils/metricsUtils';
import { filterCampaignCollabs } from 'views/admin/foodfluence/utils/collabsUtils';

interface PlatformMetrics {
  likes: number;
  comments: number;
  shares: number;
  views: number;
  saves?: number;
}

interface Metrics {
  instagram: PlatformMetrics;
  tiktok: PlatformMetrics;
}

interface ContentAnalysisProps {
  campaignId: string;
}

const ContentAnalysis: React.FC<ContentAnalysisProps> = ({ campaignId }) => {
  const collabs = useContext(CollabsDataContext);
  const [metrics, setMetrics] = useState<Metrics>({
    instagram: { likes: 0, comments: 0, shares: 0, views: 0 },
    tiktok: { likes: 0, comments: 0, shares: 0, views: 0 }
  });

  useEffect(() => {
    if (!collabs || !campaignId) return;

    const campaignCollabs = filterCampaignCollabs(collabs, campaignId);
    const platformMetrics = calculatePlatformMetrics(campaignCollabs);
    setMetrics(platformMetrics);
  }, [collabs, campaignId]);

  const calculateContentTypes = () => {
    if (!collabs) return [];

    const counts = {
      instagramReels: 0,
      instagramStories: 0,
      instagramPosts: 0,
      tiktoks: 0,
    };

    // Filter for campaign collabs and count actual deliverable links
    Object.values(collabs)
      .filter(collab => collab.influencerCampaignID === campaignId)
      .forEach(collab => {
        if (collab.deliverableLinks) {
          // Count Instagram Reels
          if (collab.deliverableLinks.instagramReels) {
            counts.instagramReels += Object.keys(collab.deliverableLinks.instagramReels).length;
          }
          
          // Count Instagram Stories
          if (collab.deliverableLinks.instagramStories) {
            counts.instagramStories += Object.keys(collab.deliverableLinks.instagramStories).length;
          }
          
          // Count Instagram Posts
          if (collab.deliverableLinks.instagramPosts) {
            counts.instagramPosts += Object.keys(collab.deliverableLinks.instagramPosts).length;
          }
          
          // Count TikTok Videos
          if (collab.deliverableLinks.tiktoks) {
            counts.tiktoks += Object.keys(collab.deliverableLinks.tiktoks).length;
          }
        }
      });

    const total = Object.values(counts).reduce((sum, count) => sum + count, 0);

    return [
      {
        id: 'Instagram Reels',
        label: 'Instagram Reels',
        value: counts.instagramReels,
        color: '#C9E4CA',
        icon: FaPlayCircle
      },
      {
        id: 'Instagram Stories',
        label: 'Instagram Stories',
        value: counts.instagramStories,
        color: '#87BBA2',
        icon: FaCircle
      },
      {
        id: 'Instagram Posts',
        label: 'Instagram Posts',
        value: counts.instagramPosts,
        color: '#55828B',
        icon: FaImage
      },
      {
        id: 'TikTok Videos',
        label: 'TikTok Videos',
        value: counts.tiktoks,
        color: '#3B6064',
        icon: FaPlayCircle
      }
    ];
  };

  const pieData = calculateContentTypes();

  return (
    <Box 
      border="2px solid"
      borderColor="gray.300"
      borderRadius="lg" 
      p={6}
      height="400px"
    >
      <Text fontSize="lg" fontWeight="semibold" mb={6}>
        Content Analysis
      </Text>
      
      <Flex gap={8}>
        <Box flex={1} pr={8}>
          <Box height="160px" position="relative" ml={-4} width="100%">
            <ResponsivePie
              data={pieData}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={{ datum: 'data.color' }}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              enableArcLinkLabels={false}
              enableArcLabels={false}
              animate={true}
              motionConfig="gentle"
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" mt={6}>
            <VStack align="flex-start" spacing={2}>
              {pieData.map((content) => (
                <Flex key={content.id} align="center" gap={2}>
                  <Box as={content.icon} color={content.color} fontSize="16px" />
                  <Text fontSize="sm">
                    {content.label} ({Math.round((content.value / pieData.reduce((sum, d) => sum + d.value, 0)) * 100)}%)
                  </Text>
                </Flex>
              ))}
            </VStack>
          </Box>
        </Box>

        {/* Platform Metrics */}
        <Box flex={1}>
          <VStack align="stretch" spacing={6}>
            {/* Instagram Metrics */}
            <Box>
              <Flex align="center" gap={2} mb={2}>
                <Box as={FaInstagram} fontSize="20px" color="purple.500" />
                <Text fontSize="sm" fontWeight="medium">Instagram</Text>
              </Flex>
              <VStack align="stretch" spacing={2} pl={7}>
                {Object.entries({
                  likes: formatCount(metrics.instagram.likes),
                  comments: formatCount(metrics.instagram.comments),
                  shares: formatCount(metrics.instagram.shares),
                  views: formatCount(metrics.instagram.views || 0)
                }).map(([key, value]) => (
                  <Flex key={key} justify="space-between">
                    <Text fontSize="sm" color="gray.500">{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                    <Text fontSize="sm" fontWeight="medium">{value}</Text>
                  </Flex>
                ))}
              </VStack>
            </Box>

            {/* TikTok Metrics */}
            <Box>
              <Flex align="center" gap={2} mb={2}>
                <Box as={FaTiktok} fontSize="20px" color="black" />
                <Text fontSize="sm" fontWeight="medium">TikTok</Text>
              </Flex>
              <VStack align="stretch" spacing={2} pl={7}>
                {Object.entries({
                  likes: formatCount(metrics.tiktok.likes),
                  comments: formatCount(metrics.tiktok.comments),
                  shares: formatCount(metrics.tiktok.shares),
                  views: formatCount(metrics.tiktok.views)
                }).map(([key, value]) => (
                  <Flex key={key} justify="space-between">
                    <Text fontSize="sm" color="gray.500">{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
                    <Text fontSize="sm" fontWeight="medium">{value}</Text>
                  </Flex>
                ))}
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContentAnalysis;