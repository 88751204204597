import React from 'react';
import { SimpleGrid,Text } from '@chakra-ui/react';
import { DeliverableLink } from '../../types';
import MediaComponent from './MediaComponent';

interface MediaGridProps {
  mediaArray: DeliverableLink[];
  onMediaClick: (media: DeliverableLink) => void;
  onPlayPause: (e: React.MouseEvent, mediaUrl: string) => void;
}

const MediaGrid: React.FC<MediaGridProps> = ({ mediaArray, onMediaClick, onPlayPause }) => {
  if (!mediaArray || mediaArray.length === 0) {
    return <Text>No media available.</Text>;
  }
  return (
    <SimpleGrid columns={[1, 2, 3]} spacing={6}>
      {mediaArray.map((media, index) => (
        <MediaComponent
          key={index}
          media={media}
          onPlayPause={onPlayPause}
          onMediaClick={onMediaClick}
          index={index}
        />
      ))}
    </SimpleGrid>
  );
};

export default MediaGrid; 