import React, { useState } from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

interface InstagramProfilePicProps {
  profile_pic_url: string;
  instagramHandle: string;
  imageSize: string;
}

const InstagramProfilePic: React.FC<InstagramProfilePicProps> = ({
  profile_pic_url,
  instagramHandle,
  imageSize
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <Box
      overflow="hidden"
      borderRadius="360px"
      width={imageSize}
      height={imageSize}
      backgroundColor="gray.200"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {loading && (
        <Spinner 
          size="md" 
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          margin="auto"
        />
      )}
      {profile_pic_url && (
        <img 
          src={profile_pic_url} 
          alt={`${instagramHandle}'s Instagram Profile`} 
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: loading ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out'
          }}
        />
      )}
      {error && (
        <Text fontSize="sm" color="gray.500" textAlign="center">
          {instagramHandle}
        </Text>
      )}
    </Box>
  );
};

export default InstagramProfilePic;