import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Icon, Text as ChakraText } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaUserCircle } from 'react-icons/fa';

interface AccountsDropdownMenuProps {
  setFilteredClientId: (clientId: string) => void;
  setSelectedAccountName: (name: string) => void;
  clientAccounts?: any[];
  filteredClientId: string;
  selectedAccountName: string;
}

const AccountsDropdown: React.FC<AccountsDropdownMenuProps> = ({
  setFilteredClientId,
  setSelectedAccountName,
  clientAccounts,
  filteredClientId,
  selectedAccountName
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node) &&
      isOpen
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [isOpen]);

  useEffect(() => {
    const getAccountName = () => {
      let account = '';
      if (!filteredClientId && clientAccounts?.length) {
        const { accountName = '', clientID = '' } = clientAccounts[0];
        account = accountName;
        setFilteredClientId(clientID);
      }

      if (
        filteredClientId &&
        clientAccounts?.length &&
        filteredClientId !== 'allAccounts'
      ) {
        const { accountName = '' } = clientAccounts.find(
          (a) => a.clientID === filteredClientId
        );
        account = accountName;
      }
      return account;
    };

    setSelectedAccountName(getAccountName());
  }, [filteredClientId, clientAccounts]);

  const updateFilterName = (clientId: string) => {
    const { accountName: updatedAccountName = '' } = clientAccounts.find(
      (a) => a.clientID === clientId
    ) || {};
    setSelectedAccountName(updatedAccountName);
  };

  return (
    <Box position="relative" width="50px">
      <Flex
        align="center"
        py={2}
        px={3}
        bg="#F4F4F4"
        borderRadius="50%" // Changed to make the icon a circle
        cursor="pointer"
        onClick={toggleDropdown}
        ref={dropdownRef}
        height="50px"
        minHeight="50px"
        justifyContent="center"
      >
        <Icon as={FaUserCircle} w="6" h="6" color="black" />
        {isOpen ? (
          <ChevronUpIcon w="5" h="5" color="grey" />
        ) : (
          <ChevronDownIcon w="5" h="5" color="grey" />
        )}
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          right="0"
          bg="#F4F4F4"
          boxShadow="sm"
          borderRadius="5px"
          mt="1"
          zIndex="2"
          maxHeight={200}
          overflow="auto"
          width="200px"
        >
          <Flex direction="column" p="1">
            {clientAccounts?.map(({ accountName, clientID }, index) => (
              <Box
                key={index}
                cursor="pointer"
                py={2}
                px={3}
                borderRadius="5px"
                _hover={{
                  bg:
                    filteredClientId === clientID ? '#90F831' : 'gray.200'
                }}
                onClick={() => {
                  setFilteredClientId(clientID);
                  updateFilterName(clientID);
                  toggleDropdown();
                }}
              >
                <ChakraText fontSize="16px">{accountName}</ChakraText>
              </Box>
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default AccountsDropdown;