import React, { createContext, useState, useEffect } from "react";
import { collection, onSnapshot, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { InfluencerCampaign } from "../views/admin/foodfluence/types";

// Define the context type
export const InfluencerCampaignsContext = createContext<Record<string, InfluencerCampaign>>({});

// Add this type declaration
type ProviderProps = {
  children: React.ReactNode;
  clientId: string;
};

export const InfluencerCampaignsProvider = ({ children, clientId }: ProviderProps) => {
  const [campaigns, setCampaigns] = useState<Record<string, InfluencerCampaign>>({});

  useEffect(() => {
    if (!clientId) return;

    // Set up real-time listener
    const campaignsRef = collection(firestore, "influencerCampaigns");
    const campaignQuery = query(campaignsRef, where("clientID", "==", clientId));
    
    const unsubscribe = onSnapshot(campaignQuery, (snapshot) => {
      const clientCampaigns = {};
      
      snapshot.forEach((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          clientCampaigns[doc.id] = {
            id: doc.id,
            ...data
          };
        }
      });
      
      setCampaigns(clientCampaigns);
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, [clientId]);

  return (
    <InfluencerCampaignsContext.Provider value={campaigns ?? {}}>
      {children}
    </InfluencerCampaignsContext.Provider>
  );
};

export default InfluencerCampaignsProvider;
