import React, { createContext, useState, useEffect, useContext } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollabsDataContext } from "./collabsDataContext";
export const FoodfluenceLocationsDataContext = createContext({});

export const FoodfluenceLocationsDataProvider = ({ children }) => {
  const collabs = useContext(CollabsDataContext);
  const [foodfluenceLocationsData, setFoodfluenceLocationsData] = useState({});

  useEffect(() => {
    if (!collabs || Object.keys(collabs).length === 0) return;

    const uniqueLocationIds = new Set(
      Object.values(collabs)
        .map(collab => collab.selectedLocation)
        .filter(location => location != null)
    ); 

    const unsubscribes = [];

    uniqueLocationIds.forEach(locationId => {
      const locationDocRef = doc(firestore, "foodfluenceLocations", locationId);
      
      const unsubscribe = onSnapshot(locationDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFoodfluenceLocationsData(prevData => ({
            ...prevData,
            [locationId]: { id: locationId, ...data }
          }));
        }
      });

      unsubscribes.push(unsubscribe);
    });

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [collabs]);

  return (
    <FoodfluenceLocationsDataContext.Provider value={foodfluenceLocationsData ?? {}}>
      {children}
    </FoodfluenceLocationsDataContext.Provider>
  );
};

export default FoodfluenceLocationsDataProvider;