import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  Input,
  VStack,
  Box,
  Text,
  Spinner,
  useColorModeValue,
  FormControl,
  FormLabel,
  Flex,
  HStack,
  Icon,
  IconButton,
  Button
} from '@chakra-ui/react';
import { FaMapMarkerAlt, FaTrash, FaPlus } from 'react-icons/fa';
import { FoodfluenceLocation } from '../types';
import Environment from "environment";

interface AddressInputProps {
  locations: FoodfluenceLocation[];
  setLocations: (locations: FoodfluenceLocation[]) => void;
  isRequired?: boolean;
}

// Add this interface to match your backend response
interface Suggestion {
  description: string;
  placeId: string;
  active: boolean;
}

const AddressInput: React.FC<AddressInputProps> = ({ locations, setLocations, isRequired = false }) => {
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showAddressInput, setShowAddressInput] = useState(false);
  const suggestionBg = useColorModeValue('white', 'gray.700');
  const suggestionHoverBg = useColorModeValue('gray.100', 'gray.600');

  const handleAddressChange = async (newAddress: string) => {
    setAddress(newAddress);
    
    if (newAddress.length < 3) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${Environment.FLASK_HOST_URL_FOODFLUENCE}/places/autocomplete?input=${encodeURIComponent(newAddress)}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Environment.FLASK_API_KEY
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
      }

      const data = await response.json();
      console.log('Suggestions response:', data);
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError('Failed to fetch suggestions');
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (selectedAddress: string, placeId: string) => {
    setAddress(selectedAddress);

    try {
      const response = await fetch(
        `${Environment.FLASK_HOST_URL_FOODFLUENCE}/places/details?place_id=${placeId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Environment.FLASK_API_KEY
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch place details');
      }

      const locationData = await response.json();
      console.log('Location details response:', locationData);
      setLocations([...locations, locationData]);
      setAddress('');
      setShowAddressInput(false);
    } catch (error) {
      console.error('Error fetching address details:', error);
      setError('Failed to fetch address details');
    }
  };

  return (
    <FormControl isInvalid={isRequired && locations.length === 0}>
      <FormLabel fontWeight="bold">Restaurant Locations</FormLabel>
      
      {!showAddressInput ? (
        <Button 
          leftIcon={<FaPlus />} 
          colorScheme="green" 
          variant="outline"
          onClick={() => setShowAddressInput(true)}
          mb={4}
        >
          Add Location
        </Button>
      ) : (
        <Box position="relative" width="100%" mb={4}>
          <Input
            value={address}
            onChange={(e) => handleAddressChange(e.target.value)}
            placeholder="Search for restaurant locations..."
            aria-label="Search for restaurant locations"
            size="md"
          />
          
          {(loading || suggestions.length > 0) && (
            <Box
              position="absolute"
              zIndex="1000"
              width="100%"
              mt={1}
              borderRadius="md"
              boxShadow="lg"
              maxH="300px"
              overflowY="auto"
              bg={suggestionBg}
              border="1px solid"
              borderColor="gray.200"
            >
              {loading && (
                <Box p={4} textAlign="center">
                  <Spinner size="sm" /> Loading...
                </Box>
              )}
              <VStack align="stretch" spacing={0}>
                {suggestions.map((suggestion) => (
                  <Box
                    key={suggestion.placeId}
                    p={3}
                    cursor="pointer"
                    _hover={{ bg: suggestionHoverBg }}
                    onClick={() => handleSelect(suggestion.description, suggestion.placeId)}
                  >
                    <Text fontSize="sm">{suggestion.description}</Text>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </Box>
      )}
      
      {isRequired && locations.length === 0 && (
        <Text color="red.500" fontSize="sm" mt={1}>
          Please add at least one restaurant location
        </Text>
      )}
      {error && (
        <Text color="red.500" mt={2}>
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default AddressInput;