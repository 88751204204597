import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Flex,
  Box,
} from '@chakra-ui/react';

interface FullscreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  alt: string;
  isVideo: boolean;
}

const FullscreenModal: React.FC<FullscreenModalProps> = ({
  isOpen,
  onClose,
  imageUrl,
  alt,
  isVideo
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none" w="fit-content">
        <Box position="relative">
          <ModalBody p={0}>
            <Flex justify="center" align="center">
              {isVideo ? (
                <video
                  controls
                  style={{
                    maxWidth: '90vw',
                    maxHeight: '80vh',
                    objectFit: 'contain'
                  }}
                >
                  <source src={imageUrl} type="video/mp4" />
                  An error has occurred loading the video.
                </video>
              ) : (
                <Image
                  src={imageUrl}
                  alt={alt}
                  maxH="80vh"
                  maxW="90vw"
                  objectFit="contain"
                />
              )}
            </Flex>
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default FullscreenModal; 