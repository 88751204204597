import { async } from "@firebase/util";
import {
  addDoc,
  arrayRemove,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  deleteDoc,
  Timestamp,
  query,
  where,
  getDocs,
  DocumentReference,
  FieldValue,
  limit
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { CollaborationsData, Influencer, InfluencerCampaign, FoodfluenceLocation } from "views/admin/foodfluence/types";
import { firestore, storage } from "../firebase"; // Adjust the path to where your Firebase config is initialized

interface CollabLog {
  status: string;
  timestamp: Timestamp;
}

// collections
const clientsCollection = collection(firestore, "clients");
const waitListCollection = collection(firestore, "waitList");
const customerGroupCollection = collection(firestore, "customerGroups");
const customerGroupStatsCollection = collection(
  firestore,
  "customerGroupStats"
);

const autopilotCollection = collection(firestore, "autopilot");

const clientDashboardAdminCollection = collection(
  firestore,
  "clientDashboardAdmin"
);
const offersCollection = collection(firestore, "offers");
const batchCodeCollection = collection(firestore, "batchCodes");
const locationsCollection = collection(firestore, "locations");
const reviewContestsCollection = collection(firestore, "reviewContests");
const influencerCampaignsCollection = collection(firestore, "influencerCampaigns")
export const getDocFromCollection = async (
  collectionName: string,
  docId: string
) => {
  const docRef = doc(firestore, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), id: docSnap.id };
  } else {
    console.log("No such document!");
    return null;
  }
};

export const doesDocExist = async (collectionName: string, docId: string) => {
  const docRef = doc(firestore, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  } else {
    console.log("No such document!");
    return false;
  }
};

export const createNewWaitListDoc = async (
  name: string,
  email: string,
  phoneNumber: string,
  company: string
) => {
  const waitListData = {
    name: name,
    email: email,
    phoneNumber: phoneNumber, 
    company: company,
  };

  try {
    const newDocRef = await addDoc(waitListCollection, waitListData);
    console.log("Document added with ID:", newDocRef.id);
  } catch (error) {
    console.error("Error adding document:", error);
  }
};

export const createNewClientDoc = async (
  clientID: string,
  fbProfilePicURL: string,
  accountName: string,
  website: string,
  locationless: boolean,
  email: string,
  instagramProfileObj: { [key: string]: any },
  tiktokHandle: string,
  bannerPictureURL: string,
  instagramHandle: string
) => {
  try {
    const newClientData = {
      accountName: accountName,
      website: website,
      locationless: locationless,
      clientID: clientID,
      email: email,
      verificationStatus: instagramProfileObj.is_verified ?? false,
      ecommerceOfferIDs: [] as string[],
      industries: [] as string[],
      instagramBio: instagramProfileObj.biography ?? "",
      instagramDisplayName: instagramProfileObj.full_name ?? accountName,
      instagramHandle: instagramHandle,
      instagramProfilePicture: fbProfilePicURL,
      isActive: true,
      isWhiteLabeled: false,
      locations: [] as string[],
      offers: [] as string[],
      tiktokHandle: tiktokHandle,
      bannerPicture: bannerPictureURL,
    };

    const clientDocRef = doc(clientsCollection, clientID);
    await setDoc(clientDocRef, newClientData);

    console.log("Client doc added with ID:", clientID);
  } catch (error) {
    console.error("Error adding document:", error);
  }
};

export const updateAdminUserClientIds = async (
  adminUserID: string,
  newClientIdList: string[]
) => {
  console.log(newClientIdList);

  try {
    // update the document field "clientIDs" with the newCLientIdList

    const adminUserDocRef = doc(clientDashboardAdminCollection, adminUserID); // Create a reference to the document

    await updateDoc(adminUserDocRef, {
      clientIDs: newClientIdList,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const addCustomerGroupToClientDoc = async (
  clientID: string,
  customerGroupId: string
) => {
  try {
    const clientDocRef = doc(clientsCollection, clientID);
    const docSnapshot = await getDoc(clientDocRef);
    const clientData = docSnapshot.data();

    const currentCustomerGroups = clientData?.customerGroups || [];

    const updatedCustomerGroups = [...currentCustomerGroups, customerGroupId];

    await updateDoc(clientDocRef, {
      customerGroups: updatedCustomerGroups,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const updateOfferActiveStatus = async (
  offerID: string,
  isActive: boolean,
  isDeleted: boolean
) => {
  try {
    const offerDocREf = doc(offersCollection, offerID);

    await updateDoc(offerDocREf, {
      isActive: isActive,
      isDeleted
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const removeOfferFromClientDoc = async (
  clientID: string,
  offerID: string
) => {
  try {
    const clientDocRef = doc(clientsCollection, clientID);

    await updateDoc(clientDocRef, {
      offers: arrayRemove(offerID),
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};


/**
 * @info changes made by nirmal on 22/05/2024 remove offer from location
 * This function will call while deleting offer and it that case location should be there with the offer.
 * @param offerID 
 * @param locationsToRemove 
 */
export const removeOfferFromLocationDoc = async (
  offerID: string,
  locationsToRemove: string[]
) => {
  try {

    if (locationsToRemove?.length) {
      // Remove offer ID from locations 
      for (let i = 0; i < locationsToRemove.length; i++) {
        const locationDocRef = doc(firestore, "locations", locationsToRemove[i]);
        await updateDoc(locationDocRef, {
          offers: arrayRemove(offerID),
        });
      }
    }
  } catch (error) {
    console.error("Error removing offers from location document:", error);
  }
};

export const removeLocationFromClientDoc = async (
  clientID: string,
  locationID: string
) => {
  try {
    const clientDocRef = doc(clientsCollection, clientID);

    await updateDoc(clientDocRef, {
      locations: arrayRemove(locationID),
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const uploadClientProfilePicToFirebase = async (
  imageData: Uint8Array,
  clientID: string
) => {
  try {
    const storageRef = ref(storage, `Client-ProfilePics/${clientID}.jpg`);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const profilePicURL = await getDownloadURL(storageRef);

    console.log("Profile Picture URL:", profilePicURL);

    return profilePicURL;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};

export const uploadImageExampleUGC = async (
  imageData: Uint8Array,
  clientID: string,
  offerID: string
) => {
  try {
    const exampleUGCPath = `Instagram-Example-UGC/${clientID}/${offerID}_${Math.random()
      .toString(36)
      .substr(2, 9)}.jpg`;

    const storageRef = ref(storage, exampleUGCPath);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const exampleUGCURL = await getDownloadURL(storageRef);

    console.log("Example UGC URL:", exampleUGCURL);

    return exampleUGCURL;
  } catch (error) {
    console.error("Error uploading example URL:", error);
    throw error;
  }
};

export const uploadOfferImage = async (
  imageData: Uint8Array,
  clientID: string,
  offerID: string
) => {
  try {
    const exampleUGCPath = `Offer-Image/${clientID}/${offerID}_${Math.random()
      .toString(36)
      .substr(2, 9)}.jpg`;

    const storageRef = ref(storage, exampleUGCPath);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const offerImageUrl = await getDownloadURL(storageRef);

    console.log("Example UGC URL:", offerImageUrl);

    return offerImageUrl;
  } catch (error) {
    console.error("Error uploading Offer Image:", error);
    throw error;
  }
};

export const uploadClientBannerPicture = async (
  imageData: Uint8Array,
  clientID: string
) => {
  try {
    const exampleUGCPath = `Client-BannerPics/${clientID}_${Math.random()
      .toString(36)
      .substr(2, 9)}.jpg`;

    const storageRef = ref(storage, exampleUGCPath);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const bannerPictureURL = await getDownloadURL(storageRef);

    console.log("Banner Picture URL:", bannerPictureURL);

    return bannerPictureURL;
  } catch (error) {
    console.error("Error uploading banner picture URL:", error);
    throw error;
  }
};

export const uploadVideoExampleUGC = async (
  videoData: Uint8Array,
  clientID: string,
  offerID: string
) => {
  try {
    const exampleUGCPath = `Instagram-Example-UGC/${clientID}/${offerID}_${Math.random()
      .toString(36)
      .substr(2, 9)}.mp4`;

    const storageRef = ref(storage, exampleUGCPath);

    // Convert the Uint8Array to a Blob
    const videoBlob = new Blob([videoData], { type: "video/mp4" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, videoBlob);

    // Get the download URL for the uploaded file
    const exampleUGCURL = await getDownloadURL(storageRef);

    console.log("Example UGC URL:", exampleUGCURL);

    return exampleUGCURL;
  } catch (error) {
    console.error("Error uploading example URL:", error);
    throw error;
  }
};

export const createNewOfferFlowDoc = async (
  offerID: string,
  clientID: string,
  offerImageLink: string = "",
  fbExampleUGCLink: string,
  offerDataMap: { [key: string]: any },
  date: number,
  specificProduct: string,
  hashtag: string,
  minimumFollowerCount: number,
  rewardClientIDs: string[],
  rewardClientNames: string[],
  influencerPhoneNumbers: string[],
  privateOfferPhoneList: string[]
) => {
  try {
    const newOfferData = {
      // add all the offer fields here
      offerID: offerID,
      clientID: clientID,
      exampleUGC: fbExampleUGCLink,
      offerImage: offerImageLink,
      offerName: offerDataMap.get("offerName")?.trim(),
      locations: offerDataMap.get("locations"),
      compensation: offerDataMap.get("compensation") ?? 0,
      minCompensation: offerDataMap.get("minCompensation") ?? 0,
      maxCompensation: offerDataMap.get("maxCompensation") ?? 0,
      typeOfOffer: offerDataMap.get("typeOfOffer"),
      isCash: offerDataMap.get("isCash"),
      isCashBack: offerDataMap.get("isCashBack"),
      percentOrCashOff: offerDataMap.get("percentOrCashOff"),
      quantity: offerDataMap.get("quantity"),
      numToPost: 1,
      totalCost: offerDataMap.get("totalCost") ?? 0,
      specificProduct: specificProduct,
      hashtag: hashtag ?? "",
      date: date,
      minimumFollowerCount: minimumFollowerCount,
      // as of now mapped customer group with this key
      customerGroup: offerDataMap.get("chosenCustomerGroups") ?? [],
      isActive: true,
      isPrivate: offerDataMap.get("isPrivate"),
      isCustomerOffer: offerDataMap.get("isCustomerOffer"),
      isInfluencerOffer: offerDataMap.get("isInfluencerOffer"),
      numberOffersLeft: offerDataMap.get("numberOffersLeft"),
      privateOfferPhoneList: [
        ...new Set(
          privateOfferPhoneList ?? offerDataMap.get("privateOfferPhoneList")
        ),
      ],
      contentType: offerDataMap.get("contentType"),
      integrations: offerDataMap.get("integrations") ?? {},
      discountItem: offerDataMap.get("discountItem"),
      discountItemID: offerDataMap.get("discountItemID"),
      discountCollectionID: offerDataMap.get("discountCollectionID"),
      minPurchaseAmount: offerDataMap.get("minPurchaseAmount") ?? 0,
      rewardClientIDs: [...new Set(rewardClientIDs)],
      rewardClientNames: [...new Set(rewardClientNames)],
      influencerPhoneNumbers: [
        ...new Set(
          influencerPhoneNumbers ?? offerDataMap.get("influencerPhoneNumbers")
        ),
      ],
      prerequisiteName: offerDataMap.get("prerequisiteName") ?? "",
      prerequisiteQuantity: offerDataMap.get("prerequisiteQuantity") ?? 0,
      prerequisiteCollectionID:
        offerDataMap.get("prerequisiteCollectionID") ?? "", // not necessary in the file but good to have for tracebacks
      expirationLimit: Number(offerDataMap.get("expirationLimit")) ?? 30,
      isFreeReward: offerDataMap.get("isFreeReward"),
      // commented this code this is not needed after the discussion on 
      // date :- 07/05/2024
      // rewardProductId: offerDataMap.get("rewardProductId") ?? "",
      // rewardItem: offerDataMap.get("rewardItem") ?? "",
      // rewardCollectionId: offerDataMap.get("rewardCollectionId") ?? "",
      isPrerequisite: offerDataMap.get("isPrerequisite") ?? false,
      discountType: offerDataMap.get("discountType"),
      isDeleted: false, // set this flag false means offer is not deleted
      // Added this field in offer flow.
      isUploadCodes: offerDataMap.get("isUploadCodes"),
      ecommerceDiscountCode: offerDataMap.get("ecommerceDiscountCode"),
      isEvergreenCode: offerDataMap.get("isEvergreenCode"),
      isAutopilotOffer: offerDataMap.get("isAutopilotOffer") ?? false,
      // added new field for integration id
      productID: offerDataMap.get("productID") ?? "",
      collectionID: offerDataMap.get("collectionID") ?? "",
    };
    const offerDocRef = doc(offersCollection, offerID);
    await setDoc(offerDocRef, newOfferData);
    console.log("Offer doc added with ID:", offerID);
  } catch (error: any) {
    // handle error when error occur while creating offer.
    console.log("CREATE OFFER ERR:-", error);
    throw new Error(error)
  }
};


/**
 * delete offer 
 */

export const deleteOfferDoc = async (offerId) => {
  try {
    const offerDocRef = doc(offersCollection, offerId);
    await deleteDoc(offerDocRef);
    console.log(`Offer with ID ${offerId} has been deleted successfully.`);
  } catch (error: any) {
    console.error("Error deleting offer:", error);
    throw new Error(error)
  }
}

/**
 * Add offer wise batch code
 * @param offerID 
 * @param batchCodeClone 
 */
export const createOfferBatchCodes = async (offerID, batchCodeClone) => {
  // need to do from here
  try {
    const offerDocRef = doc(batchCodeCollection, offerID);
    await setDoc(offerDocRef, batchCodeClone);
    console.log("BatchCode doc added with ID:", offerID);
  } catch (error: any) {
    console.log("Add Batch code ERR:", error)
  }
}

/**
 * Add/update/remove offer wise batch code
 * @param offerID 
 * @param batchCodeClone 
 */
export const addUpdateRemoveOfferBatchCodes = async (offerID, codes, isAdd = false, isRemove = false) => {
  // need to do from here
  try {
    const batchCodeDocRef = doc(batchCodeCollection, offerID);
    if (isRemove) {
      await deleteDoc(batchCodeDocRef);
      console.log("All batch codes removed.==>")
    }
    if (isAdd) {
      const docSnapshot = await getDoc(batchCodeDocRef);
      const batchCodeData = docSnapshot?.data();
      if (batchCodeData && Object.keys(batchCodeData)?.length) {
        if (batchCodeData?.codes?.length) {
          await updateDoc(batchCodeDocRef, {
            codes: [...batchCodeData?.codes, ...(codes?.map((code) => {
              return {
                code,
                isUsed: false,
              };
            }))],
          });
          console.log("Batch code updated...")
        } else {
          await updateDoc(batchCodeDocRef, {
            codes: codes?.map((code) => {
              return {
                code,
                isUsed: false,
              };
            }),
          });
          console.log("batch code document but not batch code added...")
        }
      } else {
        // addd new batch codes here
        await setDoc(batchCodeDocRef, {
          offerID,
          codes: codes?.map((code) => {
            return {
              code,
              isUsed: false,
            };
          }),
        });

        console.log("Document added in batch code....")
      }
    }
  } catch (error: any) {
    console.log("Add Batch code ERR:", error)
  }
}

export const counterCollab = async (
  collabId: string,
  deliverableCounts: Record<string, number>,
  cash: number,
  credit: number,
): Promise<void> => {
  try {

    await updateCollabStatus(
      collabId,
      "pendingInfluencerApproval",
      {
        deliverableCounts,
        cash,
        credit,
        businessRejectedCounter: false,
      },
    );

    console.log('Collab countered successfully');
  } catch (error) {
    console.error('Error countering collab:', error);
    throw error;
  }
};

export const acceptCounter = async (
  collabId: string,
  deliverableCounts: any,
  cash: number,
  credit: number
): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "pendingRedemption", {
      deliverableCounts: deliverableCounts,
      cash: cash,
      credit: credit,
      collabAcceptedTime: Timestamp.now()
    });
  } catch (error) {
    console.error("Error accepting counter:", error);
    throw error;
  }
};

export const rejectCounter = async (collabId: string): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "pendingInfluencerApproval", {
      businessRejectedCounter: true,
    });
  } catch (error) {
    console.error("Error rejecting counter:", error);
    throw error;
  }
};



export const createNewOfferDoc = async (
  offerID: string,
  clientID: string,
  fbExampleUGCLink: string,
  offerDataMap: { [key: string]: any },
  date: number,
  specificProduct: string,
  minimumFollowerCount: number,
  rewardClientIDs: string[],
  rewardClientNames: string[],
  influencerPhoneNumbers: string[],
  privateOfferPhoneList: string[]
) => {
  try {
    const newOfferData = {
      // add all the offer fields here
      offerID: offerID,
      clientID: clientID,
      exampleUGC: fbExampleUGCLink,
      offerName: offerDataMap.get("offerName"),
      locations: offerDataMap.get("locations"),
      compensation: offerDataMap.get("compensation") ?? 0,
      minCompensation: offerDataMap.get("minCompensation") ?? 0,
      maxCompensation: offerDataMap.get("maxCompensation") ?? 0,
      typeOfOffer: offerDataMap.get("typeOfOffer"),
      isCash: offerDataMap.get("isCash"),
      percentOrCashOff: offerDataMap.get("percentOrCashOff"),
      quantity: offerDataMap.get("quantity"),
      numToPost: 1,
      totalCost: offerDataMap.get("totalCost") ?? 0,
      specificProduct: specificProduct,
      date: date,
      minimumFollowerCount: minimumFollowerCount,
      customerGroup: "",
      ecommerceDiscountCode: "",
      isActive: true,
      isPrivate: offerDataMap.get("isPrivate"),
      // isCustomerOffer: offerDataMap.get("isInfluencerOffer"),
      isInfluencerOffer: offerDataMap.get("isInfluencerOffer"),
      numberOffersLeft: offerDataMap.get("quantity"),
      privateOfferPhoneList:
        privateOfferPhoneList ?? offerDataMap.get("privateOfferPhoneList"),
      contentType: offerDataMap.get("contentType"),
      integrations: offerDataMap.get("integrations") ?? {},
      discountItem: offerDataMap.get("discountItem"),
      discountItemID: offerDataMap.get("discountItemID"),
      discountCollectionID: offerDataMap.get("discountCollectionID"),
      minPurchaseAmount: offerDataMap.get("minPurchaseAmount") ?? 0,
      rewardClientIDs: rewardClientIDs ?? [],
      rewardClientNames: rewardClientNames ?? [],
      influencerPhoneNumbers: influencerPhoneNumbers ?? [],
      prerequisiteName: offerDataMap.get("prerequisiteName") ?? "",
      prerequisiteQuantity: offerDataMap.get("prerequisiteQuantity") ?? 0,
      prerequisiteCollectionID:
        offerDataMap.get("prerequisiteCollectionID") ?? "", // not necessary in the file but good to have for tracebacks
    };

    const offerDocRef = doc(offersCollection, offerID);
    await setDoc(offerDocRef, newOfferData);
    console.log("Offer doc added with ID:", offerID);
  } catch (error: any) {
    console.error("Error adding document:", error);
    throw new Error(error)

  }
};

export const createNewLocationDoc = async (
  clientID: string,
  address: string,
  latitude: number,
  longitude: number
) => {
  try {
    const locationDocRef = doc(locationsCollection);
    const newLocationData = {
      clientID: clientID,
      locationID: locationDocRef.id,
      address: address,
      latitude: latitude,
      longitude: longitude,
      offers: [] as string[],
    };

    await setDoc(locationDocRef, newLocationData);

    console.log("Location doc added with ID:", locationDocRef.id);

    return locationDocRef.id;
  } catch (error) {
    console.error("Error adding document:", error);
  }
};

export const updateClientLocationIds = async (
  clientID: string,
  newLocationIdList: string[]
) => {
  try {
    const clientDocRef = doc(clientsCollection, clientID);

    await updateDoc(clientDocRef, {
      locations: newLocationIdList,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const updateClientOfferIds = async (
  clientID: string,
  newOfferIdList: string[]
) => {
  try {
    const clientDocRef = doc(clientsCollection, clientID);

    await updateDoc(clientDocRef, {
      offers: newOfferIdList,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const generateID = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters[randomIndex];
  }

  return id;
};

export const getCurrentUTCTimestamp = () => {
  const now = new Date();
  return Math.floor(now.getTime() / 1000);
};

export const updateCollabStatus = async (collabId: string, newStatus: string, additionalFields: Record<string, any> = {}): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const now: Timestamp = Timestamp.now();

    const updateData: {
      status: string;
      logs: CollabLog[] | FieldValue;
      [key: string]: any;
    } = {
      status: newStatus,
      logs: arrayUnion({
        status: newStatus,
        timestamp: now,
      }),
      ...additionalFields,
    };

    await updateDoc(collabRef, updateData);
    console.log(`Collab ${newStatus} successfully`);
  } catch (error) {
    console.error(`Error updating collab status to ${newStatus}:`, error);
    throw error;
  }
};

// export const updateLocationOfferIds = async (
//   locationIDs: string[],
//   offerIDToAdd: string,
//   existingLocations?: string[]
// ) => {
//   try {

//     if (existingLocations?.length) {

//     }
//     for (let i = 0; i < locationIDs.length; i++) {
//       const locationDocRef = doc(firestore, "locations", locationIDs[i]);
//       const docSnap = await getDoc(locationDocRef);

//       if (docSnap.exists()) {
//         const location = docSnap.data();
//         const currentOffers = location.offers;
//         const newOffers = [...currentOffers, offerIDToAdd];

//         await updateDoc(locationDocRef, {
//           offers: newOffers,
//         });
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };



/**
 * 
 * @param locationIDs 
 * @param offerIDToAdd 
 * @param existingLocations 
 * @returns 
 * @info Changes made by nirmal on 20/may/2024 for offerId add/remove from location while offer add/update
 */
export const updateLocationOfferIds = async (
  locationIDs: string[],
  offerIDToAdd: string,
  existingLocations: string[] = []
) => {
  console.log("add/update offer with location called...")
  try {
    if (!locationIDs?.length && !existingLocations?.length) {
      return;
    }

    // If existingLocations is empty, just add the offerID to the new locations
    if (existingLocations.length === 0) {
      for (let i = 0; i < locationIDs.length; i++) {
        const locationDocRef = doc(firestore, "locations", locationIDs[i]);
        await updateDoc(locationDocRef, {
          offers: arrayUnion(offerIDToAdd),
        });
      }
      return; // Exit the function as no removal is needed
    }

    // Convert to sets for easier comparison
    const currentSet = new Set(existingLocations);
    const newSet = new Set(locationIDs);

    // Check if both sets have the same IDs
    const setsAreEqual = currentSet.size === newSet.size && [...currentSet].every(id => newSet.has(id));
    console.log("setsAreEqual==>", setsAreEqual)
    if (setsAreEqual) {
      return; // No operation needed if both sets are equal
    }



    // Locations to add the offer to (in newSet but not in currentSet)
    const locationsToAdd = locationIDs.filter(id => !currentSet.has(id));
    console.log("locationsToAdd==>", locationsToAdd)
    // Locations to remove the offer from (in currentSet but not in newSet)
    const locationsToRemove = existingLocations.filter(id => !newSet.has(id));
    console.log("locationsToRemove==>", locationsToRemove)

    if (locationsToAdd?.length) {
      // Add offer ID to new locations
      for (let i = 0; i < locationsToAdd.length; i++) {
        const locationDocRef = doc(firestore, "locations", locationsToAdd[i]);
        await updateDoc(locationDocRef, {
          offers: arrayUnion(offerIDToAdd),
        });
      }
    }

    if (locationsToRemove?.length) {
      // Remove offer ID from old locations no longer present
      for (let i = 0; i < locationsToRemove.length; i++) {
        const locationDocRef = doc(firestore, "locations", locationsToRemove[i]);
        await updateDoc(locationDocRef, {
          offers: arrayRemove(offerIDToAdd),
        });
      }
    }

  } catch (error) {
    console.log("Error occurred while location add/update", error);
  }
};


export const createNewCustomerGroupDoc = async (
  id: string,
  clientID: string,
  customerGroupName: string,
  users: string[]
) => {
  const customerGroupCollectionData = {
    clientID: clientID,
    users: users,
    customerGroupName: customerGroupName,
  };

  try {
    const newDocRef = doc(firestore, "customerGroups", id);
    await setDoc(newDocRef, customerGroupCollectionData);
    console.log("Document added with ID:", newDocRef.id);
  } catch (error) {
    console.error("Error adding document:", error);
  }
};

export const updateCustomerGroupDoc = async (
  customerGroupID: string,
  users: string[]
) => {
  try {
    const customerGroupDocRef = doc(customerGroupCollection, customerGroupID);
    await updateDoc(customerGroupDocRef, {
      users: users,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const isAutopilotDocCreated = async (clientID: string) => {
  const docRef = doc(autopilotCollection, clientID);
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.exists;
};

export const createAutopilotDoc = async (
  autopilotDataMap: Map<string, any>
) => {
  try {
    // Convert Map objects to plain objects
    const mapToObject = (map: Map<string, any>) => {
      const out = Object.create(null);
      map.forEach((value, key) => {
        // Recursively convert value if it's a Map
        if (value instanceof Map) {
          out[key] = mapToObject(value);
        } else {
          out[key] = value;
        }
      });
      return out;
    };

    const newAutoPilotData = {
      clientID: autopilotDataMap.get("clientID"),
      instagramStory: mapToObject(
        autopilotDataMap.get("instagramStory") ?? new Map()
      ),
      tiktokVideo: mapToObject(
        autopilotDataMap.get("tiktokVideo") ?? new Map()
      ),
      instagramPost: mapToObject(
        autopilotDataMap.get("instagramPost") ?? new Map()
      ),
      instagramReel: mapToObject(
        autopilotDataMap.get("instagramReel") ?? new Map()
      ),
      ugc: mapToObject(autopilotDataMap.get("ugc") ?? new Map()),
    };

    const autopilotDocRef = doc(autopilotCollection, newAutoPilotData.clientID);
    await setDoc(autopilotDocRef, newAutoPilotData);
    console.log("Autopilot doc created with ID:", newAutoPilotData.clientID);
  } catch (error) {
    console.error("Error adding document:", error);
  }
};

export const updateAutopilotDoc = async (
  autopilotDataMap: Map<string, any>
) => {
  try {
    // Convert Map objects to plain objects
    const mapToObject = (map: Map<string, any>) => {
      const out = Object.create(null);
      map.forEach((value, key) => {
        // Recursively convert value if it's a Map
        if (value instanceof Map) {
          out[key] = mapToObject(value);
        } else {
          out[key] = value;
        }
      });
      return out;
    };

    // Assuming clientID is correctly retrieved from autopilotDataMap
    const clientID = autopilotDataMap.get("clientID");
    if (!clientID) {
      throw new Error("Client ID is missing");
    }

    const updateData = {
      instagramStory: mapToObject(
        autopilotDataMap.get("instagramStory") ?? new Map()
      ),
      instagramPost: mapToObject(
        autopilotDataMap.get("instagramPost") ?? new Map()
      ),
      tiktokVideo: mapToObject(
        autopilotDataMap.get("instagramPost") ?? new Map()
      ),
      instagramReel: mapToObject(
        autopilotDataMap.get("instagramReel") ?? new Map()
      ),
      ugc: mapToObject(autopilotDataMap.get("ugc") ?? new Map()),
    };

    const autopilotDocRef = doc(autopilotCollection, clientID);
    await updateDoc(autopilotDocRef, updateData);
    console.log("Autopilot doc updated with ID:", clientID);
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const updateAutopilotContentActiveStatus = async (
  clientID: string,
  contentType: string,
  isActive: boolean
) => {
  try {
    const autopilotDocRef = doc(autopilotCollection, clientID); // Make sure clientID is used here

    const fieldPath = `${contentType}.isActive`;

    await updateDoc(autopilotDocRef, {
      [fieldPath]: isActive,
    });

    console.log("Document successfully updated");
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const updateOfferDoc = async (
  offerDataMap: { [key: string]: any },
  exampleUGCUrl: string,
  specificProduct: string,
  minimumFollowerCount: number,
  rewardClientIDs: string[],
  rewardClientNames: string[]
) => {
  try {
    const offerDocRef = doc(firestore, "offers", offerDataMap.get("offerID"));

    await updateDoc(offerDocRef, {
      exampleUGC: exampleUGCUrl,
      offerName: offerDataMap.get("offerName"),
      compensation: offerDataMap.get("compensation"),
      typeOfOffer: offerDataMap.get("typeOfOffer"),
      isCash: offerDataMap.get("isCash"),
      percentOrCashOff: offerDataMap.get("percentOrCashOff"),
      quantity: offerDataMap.get("quantity"),
      totalCost: offerDataMap.get("totalCost"),
      specificProduct: specificProduct,
      minimumFollowerCount: minimumFollowerCount,
      numberOffersLeft: offerDataMap.get("quantity"),
      contentType: offerDataMap.get("contentType"),
      integrations: offerDataMap.get("integrations") ?? {},
      discountItem: offerDataMap.get("discountItem"),
      discountItemID: offerDataMap.get("discountItemID"),
      discountCollectionID: offerDataMap.get("discountCollectionID"),
      minPurchaseAmount: offerDataMap.get("minPurchaseAmount"),
      rewardClientIDs: rewardClientIDs,
      rewardClientNames: rewardClientNames,
    });
  } catch (error) {
    console.log(error);
  }
};
export const updateNewOfferFlowDoc = async (
  offerDataMap: { [key: string]: any },
  offerImageUrl: string = "",
  exampleUGCUrl: string,
  specificProduct: string,
  hashtag: string,
  minimumFollowerCount: number,
  rewardClientIDs: string[],
  rewardClientNames: string[]
) => {
  try {
    const offerDocRef = doc(firestore, "offers", offerDataMap.get("offerID"));
    let date = getCurrentUTCTimestamp();
    await updateDoc(offerDocRef, {
      offerName: offerDataMap.get("offerName"),
      hashtag: hashtag ?? "",
      locations: offerDataMap.get("locations"),
      compensation: offerDataMap.get("compensation") ?? 0,
      minCompensation: offerDataMap.get("minCompensation") ?? 0,
      maxCompensation: offerDataMap.get("maxCompensation") ?? 0,
      typeOfOffer: offerDataMap.get("typeOfOffer"),
      isCash: offerDataMap.get("isCash"),
      percentOrCashOff: offerDataMap.get("percentOrCashOff"),
      quantity: offerDataMap.get("quantity") ?? 0,
      numToPost: 1,
      totalCost: offerDataMap.get("totalCost") ?? 0,
      specificProduct: specificProduct,
      date: date,
      minimumFollowerCount: minimumFollowerCount,
      // as of now mapped customer group with this key
      customerGroup: offerDataMap.get("chosenCustomerGroups") ?? [],
      // commented this code as we don't need this while editing offer.
      // ecommerceDiscountCode: "",
      isActive: true,
      isPrivate: offerDataMap.get("isPrivate"),
      isCustomerOffer: offerDataMap.get("isCustomerOffer"),
      isInfluencerOffer: offerDataMap.get("isInfluencerOffer"),
      numberOffersLeft: offerDataMap.get("numberOffersLeft"),
      privateOfferPhoneList: offerDataMap.get("privateOfferPhoneList") ?? [],
      contentType: offerDataMap.get("contentType"),
      integrations: offerDataMap.get("integrations") ?? {},
      discountItem: offerDataMap.get("discountItem"),
      discountItemID: offerDataMap.get("discountItemID"),
      discountCollectionID: offerDataMap.get("discountCollectionID"),
      minPurchaseAmount: offerDataMap.get("minPurchaseAmount") ?? 0,
      rewardClientIDs: [...new Set(rewardClientIDs)],
      rewardClientNames: [...new Set(rewardClientNames)],
      influencerPhoneNumbers: offerDataMap.get("influencerPhoneNumbers") ?? [],
      prerequisiteName: offerDataMap.get("prerequisiteName") ?? "",
      prerequisiteQuantity: offerDataMap.get("prerequisiteQuantity") ?? 0,
      prerequisiteCollectionID:
        offerDataMap.get("prerequisiteCollectionID") ?? "", // not necessary in the file but good to have for tracebacks
      exampleUGC: exampleUGCUrl,
      offerImage: offerImageUrl,
      isCashBack: offerDataMap.get("isCashBack"),
      expirationLimit: offerDataMap.get("expirationLimit") ?? 0,
      isFreeReward: offerDataMap.get("isFreeReward"),
      // commented this code this is not needed after the discussion on 
      // date :- 07/05/2024
      // rewardProductId: offerDataMap.get("rewardProductId") ?? "",
      // rewardItem: offerDataMap.get("rewardItem") ?? "",
      // rewardCollectionId: offerDataMap.get("rewardCollectionId") ?? "",
      isPrerequisite: offerDataMap.get("isPrerequisite") ?? false,
      discountType: offerDataMap.get("discountType"),
    });
  } catch (error: any) {
    console.log("UPDATE OFFER ERR:", error);
    throw new Error(error)
  }
};

// returns the metadata (views, likes, comments, etc.) for a given piece of content
export const getContentMetadata = async (contentLink: string) => {
  // extract the filename from the link
  // first ensure the link is decoded
  const decodedLink = decodeURIComponent(contentLink);

  if (decodedLink.includes("UGC-Offer-UGC")) {
    // for direct UGC submissions, there is no metadata
    return null;
  }

  const linkParts = decodedLink.split("/");
  const fileName = linkParts[linkParts.length - 1];
  // remove everything after the . in filename (do not include file extension or token)
  const fileNameParts = fileName.split(".");
  const fileNameStripped = fileNameParts[0];

  // extract has_metadata_flag, where the file name has the following format: f"/tmp/{phone_number}_{offer_id}_{client_id}_{score}_{has_metadata_flag}_{randint(0, 999999)}.{content_path.split('.')[-1]}"
  const hasMetadataFlag = fileNameStripped.split("_")[4];

  if (hasMetadataFlag === "0") {
    return null;
  }

  const docRef = doc(firestore, "contentMetadata", fileNameStripped);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const metadata = docSnap.data();
    return metadata;
  } else {
    console.log("No such document!!!!!!!");
    return null;
  }
};

export const updateTouchpointForClient = async (clientID: string, touchpointID: string, newTouchpointData: any) => {
  const docRef = doc(firestore, "touchpoints", clientID);
  try {
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      await setDoc(docRef, { [touchpointID]: newTouchpointData, impressions: [] });
      console.log(`Client document created with initial touchpoint ${touchpointID}`);
    } else {
      await updateDoc(docRef, {
        [touchpointID]: newTouchpointData,
      });
      console.log(`Touchpoint ${touchpointID} updated for client ${clientID}`);
    }
  } catch (error) {
    console.error("Error adding or updating touchpoint:", error);
  }
}

export const DeleteTouchpointForClient = async (clientID: string, touchpointID: string) => {
  const docRef = doc(firestore, 'touchpoints', clientID);
  const clientDoc = await getDoc(docRef);

  //add design to touchpoint
  if (clientDoc.exists()) {
  const clientData = clientDoc.data();
    try {
      if (clientData.hasOwnProperty(touchpointID)) {
        delete clientData[touchpointID];
        await setDoc(docRef, clientData);
        console.log(`Touchpoint ${touchpointID} deleted for client`);
      } else {
        console.warn(`Touchpoint ${touchpointID} not found for client`);
      }
    } catch (error) {
      console.error("Error deleting touchpoint:", error);
      throw error; 
    }
}
else{
  throw new Error(`Client with ID ${clientID} does not exist`);

}
}


export const uploadTouchpointDesign = async (
  clientID: string,
  touchpointID: string,
  imageData: Uint8Array

) => {
  try {
    const storageRef = ref(storage, `client-touchpoints/${touchpointID}.jpg`);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const touchpointDesignURL = await getDownloadURL(storageRef);
    
    const touchpointsRef = doc(firestore, "touchpoints", clientID);
    const touchpointDoc = await getDoc(touchpointsRef);

     //add design to touchpoint
    if (touchpointDoc.exists()) {
      const touchpointData = touchpointDoc.data();
      if (touchpointData && touchpointData[touchpointID]) {
        await updateDoc(touchpointsRef, {
          [touchpointID]: {
            ...touchpointData[touchpointID],
            design: touchpointDesignURL
          }
        });
      } else {
        throw new Error(`Touchpoint with ID ${touchpointID} does not exist for client ${clientID}`);
      }
    } else {
      throw new Error(`Client with ID ${clientID} does not exist`);
    }

    return touchpointDesignURL;
  } catch (error) {
    console.error("Error uploading Touchpoint design:", error);
    throw error;
  }
};

export const uploadCollabImage = async (
  clientID: String,
  campaignName: string,
  imageData: Uint8Array,
  
) => {
  try {
    const storageRef = ref(storage, `Collab-Images/${clientID}/${campaignName}.jpg`);

    // Convert the Uint8Array to a Blob
    const imageBlob = new Blob([imageData], { type: "image/jpeg" });

    // Upload the Blob to Firebase Storage
    await uploadBytes(storageRef, imageBlob);

    // Get the download URL for the uploaded file
    const collabImageURL = await getDownloadURL(storageRef);

    console.log("Collaboration Image URL:", collabImageURL);
    return collabImageURL; // Return the URL if needed
  } catch (error) {
    console.error("Error uploading collaboration image:", error);
    throw error;
  }
};

export const updateWhiteLabeledMessages = async (whiteLabeledDomain:string, newMessageData: any)=>{
  try {
  
    const whiteLabeledKitRef = doc(firestore, "whiteLabeledKits", whiteLabeledDomain);
    const whiteLabeledKitDoc = await getDoc(whiteLabeledKitRef);

    if(whiteLabeledKitDoc.exists){
      const whiteLabeledKit = whiteLabeledKitDoc.data()

      await updateDoc(whiteLabeledKitRef, {
        ...whiteLabeledKit,
        ...newMessageData
      })
    }
  } catch (error) {
    console.error("Error uploading white labeled messages:", error);
    throw error;
  }

}

export const createReviewContest = async (name: string, compensation: number, endDate: Timestamp, isActive: boolean, contestants: Record<string, any>)=>{
  const newReviewContest = {
    name,
    compensation,
    endDate,
    isActive,
    contestants,
  };
  try{
    const newDocRef = await addDoc(reviewContestsCollection, newReviewContest);
    console.log("Document added with ID:", newDocRef.id);
    return newDocRef.id
  } catch (error) {
    console.log("Error creating review contest")
  }

}

export const updateReviewContest = async (reviewContestID: string,name: string, compensation: number, endDate: Timestamp, isActive: boolean) => {
  const newReviewContest = {
    name,
    compensation,
    endDate,
    isActive,
  };
  try {
  
    const reviewContestRef = doc(firestore, "reviewContests", reviewContestID);
    const reviewContestDoc = await getDoc(reviewContestRef);

    if(reviewContestDoc.exists){
      const reviewContest = reviewContestDoc.data()

      await updateDoc(reviewContestRef, {
        ...reviewContest,
        ...newReviewContest
      })
    }
  } catch (error) {
    console.error("Error updating review contest information:", error);
    throw error;
  }

}
export const selectReviewContestWinner = async (reviewContestID: string) => {
  try {
  const reviewContestRef = doc(firestore, "reviewContests", reviewContestID);
  const reviewContestDoc = await getDoc(reviewContestRef);

  if(reviewContestDoc.exists){
    const reviewContest = reviewContestDoc.data()

    const contestantsMap = reviewContest.contestants || {};
    const contestants = Object.keys(contestantsMap).filter(
      contestantId => contestantsMap[contestantId].approved === true
    );

    if (contestants.length === 0) {
      throw new Error("No contestants found in the contest.");
    }

    const randomIndex = Math.floor(Math.random() * contestants.length);
    const winner = contestants[randomIndex];

    await updateDoc(reviewContestRef, {
      ...reviewContest,
      isActive: false,
      winner: winner
    });

  }
  } catch (error) {
    console.error("Error selecting winner: ", error);
    throw error;
  }

}

export const updateClientReviewContestInformation = async (clientID: string, reviewContestID: string, googleReviewLink: string) => {
  try {
    const clientsRef = doc(firestore, "clients", clientID);
    const clientsDoc = await getDoc(clientsRef);

    if(clientsDoc.exists){
      const clientData = clientsDoc.data()

      await updateDoc(clientsRef, {
        ...clientData,
        "reviewContestID": reviewContestID,
        "googleReviewLink": googleReviewLink ?? null
      })
    }

  } catch (error) {
    console.error("Error updating client's review contest information:", error);
    throw error;
  }

}

export const updateInfluencerBookmarkedByClient = async(clientID: string, influencerID: string) => {
  try {
    const clientsRef = doc(firestore, "clients", clientID);
    const clientsDoc = await getDoc(clientsRef);

    if(clientsDoc.exists){
      const clientData = clientsDoc.data();

      if (clientData.bookmarkedInfluencers && Array.isArray(clientData.bookmarkedInfluencers)) {
        if (clientData.bookmarkedInfluencers.includes(influencerID)) {
          await updateDoc(clientsRef, {
            ...clientData,
            bookmarkedInfluencers: arrayRemove(influencerID)
          });
        } else {
          await updateDoc(clientsRef, {
            ...clientData,
            bookmarkedInfluencers: arrayUnion(influencerID)
          });
        }
      } else {
        await updateDoc(clientsRef, {
          ...clientData,
          bookmarkedInfluencers: [influencerID]
        });
      }
    }
 } catch (error) {
    console.error("Error updating client's bookmarked information:", error);
    throw error;
  }

}

export const createNewInfluencerCampaign = async (
  clientID: string, 
  name: string, 
  description: string, 
  deliverableCounts: any, 
  cash: number, 
  credit: number,
  startDate: Date, 
  endDate: Date, 
  openHours: any, 
  collabImage: string, 
  foodfluenceLocations: string[],
  campaignType: 'inStore' | 'shipToHome' | 'DiscountCode',
  allowCounters: boolean,
  maxPartySize: number | null
) => {
  const locationIDs = foodfluenceLocations.map(loc => createLocationId(clientID, loc));

  const newCampaign = {
    clientID,
    name,
    description,
    deliverableCounts,
    cash,
    credit,
    startDate,
    endDate,
    openHours,
    collabImage,
    foodfluenceLocations: locationIDs,
    campaignType,
    allowCounters,
    maxPartySize
  }
  try{
    const newDocRef = await addDoc(influencerCampaignsCollection, newCampaign);
    console.log("Document added with ID:", newDocRef.id);
    return newDocRef.id
  } catch (error) {
    console.log("Error creating influencer campaign: ", error)
  }
}

export const updateInfluencerCampaign = async (
  campaignID: string,
  clientID: string, 
  name: string, 
  description: string, 
  deliverableCounts: any, 
  cash: number, 
  credit: number,
  startDate: Date, 
  endDate: Date, 
  openHours: any, 
  collabImage: string, 
  foodfluenceLocations: string[],
  campaignType: string,
  allowCounters: boolean,
  maxPartySize: number | null
) => {
  try {
    const locationIDs = foodfluenceLocations.map(loc => createLocationId(clientID, loc));

    const updateData = {
      clientID,
      name,
      description,
      deliverableCounts,
      cash,
      credit,
      startDate,
      endDate,
      openHours,
      collabImage,
      foodfluenceLocations: locationIDs,
      campaignType,
      allowCounters, 
      maxPartySize
    };

    const campaignDocRef = doc(influencerCampaignsCollection, campaignID);
    
    await updateDoc(campaignDocRef, updateData);
    
    console.log("Influencer campaign updated successfully");
    return true;
  } catch (error) {
    console.error("Error updating influencer campaign:", error);
    throw error;
  }
};

export const setCampaignInfluencers = async (campaignID: string, influencers: string[]) => {
  try {
    const campaignDocRef = doc(firestore, "influencerCampaigns", campaignID);

    await updateDoc(campaignDocRef, {
      influencers: influencers
    });

    console.log("Influencers updated successfully");
  } catch (error) {
    console.error("Error updating influencers:", error);
  }
};

export const createCollaboration = async(
  influencerId_campaignId: string, 
  influencerID: string, 
  influencerCampaignID: string,
  cash: number,
  credit: number,
  deliverableCounts: any,
  deliverableLinks: any,
  postMetrics: any,
  logs: any,
  status: string
 ) => {
 logs.push({ status: status, timestamp: Timestamp.now()});
 const newCollaboration = {
   influencerId_campaignId,
   influencerID,
   influencerCampaignID,
   cash,
   credit,
   deliverableCounts,
   deliverableLinks,
   postMetrics,
   logs,
   status,
   collabReceivedTime : Timestamp.now(),
 };

 try {
  const collaborationsCollection = collection(firestore, "collabs");
  const docRef = doc(collaborationsCollection, influencerId_campaignId);
  await setDoc(docRef, newCollaboration);
  console.log("Collaboration created with ID:", influencerId_campaignId);
  return influencerId_campaignId;
} catch (error) {
  console.error("Error creating collaboration:", error);
  throw error;
}
};

export const getAllCollabsNotPendingInfluencerApproval = async (): Promise<Record<string, CollaborationsData>> => {
  const collabsRef = collection(firestore, 'collabs');
  const q = query(collabsRef, where("status", "!=", "pendingInfluencerApproval"));
  const querySnapshot = await getDocs(q);
  return Object.fromEntries(
    querySnapshot.docs.map(doc => [doc.id, { id: doc.id, ...doc.data() } as CollaborationsData])
  );
};

export const getInfluencerCampaign = async (campaignID: string): Promise<InfluencerCampaign> => {
  const campaignRef = doc(firestore, 'influencerCampaigns', campaignID);
  const campaignDoc = await getDoc(campaignRef);
  return { id: campaignDoc.id, ...campaignDoc.data() } as InfluencerCampaign;
};

export const getCampaignsFromIDs = async (campaignIDs: string[]): Promise<Record<string, InfluencerCampaign>> => {
  try {
    const campaignsRef = collection(firestore, 'influencerCampaigns');
    const campaignPromises = campaignIDs.filter(id => id).map(id => 
      getDoc(doc(campaignsRef, id))
    );
    const campaignDocs = await Promise.all(campaignPromises);
    return Object.fromEntries(
      campaignDocs.map(doc => [doc.id, { id: doc.id, ...doc.data() } as InfluencerCampaign])
    );
  } catch (error) {
    console.error("Error fetching campaigns:", error);
    throw error;
  }
};


export const getAllInfluencersFromIDs = async (influencerIDs: string[]): Promise<Record<string, Influencer>> => {
  try {
    const influencersRef = collection(firestore, 'influencers');
    const influencerPromises = influencerIDs.map(id => 
      getDoc(doc(influencersRef, id))
    );
    const influencerDocs = await Promise.all(influencerPromises);
    return Object.fromEntries(
      influencerDocs
        .filter(doc => doc.exists())
        .map(doc => [doc.id, { id: doc.id, ...doc.data() } as Influencer])
    );
  } catch (error) {
    console.error("Error fetching influencers:", error);
    throw error;
  }
};

export const getAllFoodfluenceLocationsFromIDs = async (locationIDs: string[]): Promise<Record<string, FoodfluenceLocation>> => {
  try {
    const validLocationIDs = locationIDs.filter(id => id && id.trim().length > 0);
    
    if (validLocationIDs.length === 0) {
      return {};
    }

    const locationsRef = collection(firestore, 'foodfluenceLocations');
    const locationPromises = validLocationIDs.map(id => getDoc(doc(locationsRef, id)));
    const locationDocs = await Promise.all(locationPromises);
    return Object.fromEntries(
      locationDocs.map(doc => [doc.id, doc.data() as FoodfluenceLocation]));
  } catch (error) {
    console.error("Error fetching foodfluence locations:", error);
    throw error;
  }
};

export const getAllClientNamesFromIDs = async (clientIDs: string[]): Promise<Record<string, {id: string, clientName: string}>> => {
  try {
    const clientsRef = collection(firestore, 'clients');
    console.log("clientIDs", clientIDs)
    const clientPromises = clientIDs.map(id => 
      getDoc(doc(clientsRef, id))
    );
    const clientDocs = await Promise.all(clientPromises);
    return Object.fromEntries(
      clientDocs
        .filter(doc => doc.exists())
        .map(doc => [doc.id, { id: doc.id, clientName: doc.data().accountName }])
    );
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};

export const getAllInfluencers = async (): Promise<Record<string, Influencer>> => {
  try {
    const influencersRef = collection(firestore, 'influencers');
    const querySnapshot = await getDocs(influencersRef);
    return Object.fromEntries(
      querySnapshot.docs.map(doc => [doc.id, { id: doc.id, ...doc.data() } as Influencer])
    );
  } catch (error) {
    console.error("Error fetching influencers:", error);
  }
};

export const getFeaturedInfluencers = async (): Promise<Influencer[]> => {
  const influencersRef = collection(firestore, 'influencers');
  const featuredQuery = query(influencersRef, where('featured', '==', true), limit(10));
  const querySnapshot = await getDocs(featuredQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Influencer));
};

export const getInfluencersByIDs = async (influencerIDs: string[]): Promise<Record<string, Influencer>> => {
  const influencersRef = collection(firestore, 'influencers');
  
  const influencerPromises = influencerIDs.map(id => 
    getDoc(doc(influencersRef, id))
  );

  const influencerDocs = await Promise.all(influencerPromises);
  
  const influencers: Record<string, Influencer> = {};

  influencerDocs.forEach(doc => {
    if (doc.exists()) {
      influencers[doc.id] = { id: doc.id, ...doc.data() } as Influencer;
    }
  });
  return influencers;
};

export const getCollabByID = async (collabID: string): Promise<CollaborationsData> => {
  const collabRef = doc(firestore, "collabs", collabID);
  const collabDoc = await getDoc(collabRef);
  return collabDoc.data() as CollaborationsData;
};

export const createLocationId = (clientID: string, address: string): string => {
  // Normalize address: lowercase, remove special chars, replace spaces with underscores
  const normalizedAddress = address
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, '')
    .replace(/\s+/g, '_');
    
  return `${clientID}_${normalizedAddress}`;
};

export const createFoodfluenceLocation = async (location: FoodfluenceLocation, clientID: string, emails: string[]): Promise<void> => {
  const locationId = createLocationId(clientID, location.fullAddress);
  const locationRef = doc(firestore, 'foodfluenceLocations', locationId);
  try {
    await setDoc(locationRef, { ...location, clientID: clientID, emails: emails ?? [] });
  } catch (error) {
    console.error('Error creating foodfluence location:', error);
    throw error;
  }
};

export const updateFoodfluenceLocationEmails = async (location: FoodfluenceLocation, emails: string[]): Promise<void> => {
  const locationId = createLocationId(location.clientID, location.fullAddress);
  const locationRef = doc(firestore, 'foodfluenceLocations', locationId);
  await updateDoc(locationRef, { emails: emails ?? [] });
};

export const deleteFoodfluenceLocation = async (clientID: string, locationAddress: string): Promise<void> => {
  const locationId = createLocationId(clientID, locationAddress);
  const locationRef = doc(firestore, 'foodfluenceLocations', locationId);
  try {
    await deleteDoc(locationRef);
  } catch (error) {
    console.error('Error deleting foodfluence location:', error);
    throw error;
  }
};

export const getClientFoodfluenceLocations = async (clientID: string): Promise<FoodfluenceLocation[]> => {
  const locationsRef = collection(firestore, 'foodfluenceLocations');
  const clientQuery = query(locationsRef, where("clientID", "==", clientID));
  
  try {
    const querySnapshot = await getDocs(clientQuery);
    const locations: FoodfluenceLocation[] = [];
    querySnapshot.forEach(doc => {
      const data = doc.data() as FoodfluenceLocation;
      data.docId = doc.id;
      locations.push(data);
    });
    return locations;
  } catch (error) {
    console.error('Error fetching client locations:', error);
    throw error;
  }
};

export const getFoodfluenceLocation = async (locationId: string): Promise<FoodfluenceLocation> => {
  const locationRef = doc(firestore, "foodfluenceLocations", locationId);
  const locationDoc = await getDoc(locationRef);
  return locationDoc.data() as FoodfluenceLocation;
};

export const getInfluencer = async (influencerID: string): Promise<Influencer> => {
  const influencerRef = doc(firestore, "influencers", influencerID);
  const influencerDoc = await getDoc(influencerRef);
  return influencerDoc.data() as Influencer;
};

export const getClientData = async (clientID: string) => {
  const clientRef = doc(firestore, "clients", clientID);
  const clientDoc = await getDoc(clientRef);
  
  if (clientDoc.exists()) {
    return { id: clientDoc.id, ...clientDoc.data() };
  } else {
    console.log("No such client document!");
    return null;
  }
};

export const updateShippingConfirmed = async (collabId: string): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const now: Timestamp = Timestamp.now();
    
    await updateDoc(collabRef, {
      shippingConfirmed: now
    });
    
    console.log(`Successfully updated shipping confirmation for collab ${collabId}`);
  } catch (error) {
    console.error("Error updating shipping confirmation:", error);
    throw error;
  }
};


export const addTrackingNumber = async (collabId: string, trackingNumber: string): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, { trackingNumbers: arrayUnion(trackingNumber) });
  } catch (error) {
    console.error("Error adding tracking number:", error);
    throw error;
  }
};

export const updateAdminUserRemainingInvites = async (
  adminUserID: string,
  invitesUsed: number
) => {
  try {
    const adminRef = doc(clientDashboardAdminCollection, adminUserID);
    const adminDoc = await getDoc(adminRef);
    
    if (!adminDoc.exists()) {
      throw new Error("Admin user document not found");
    }

    const adminData = adminDoc.data();
    const currentInvites = adminData.invitesRemaining || 0;
    const newInvites = Math.max(0, currentInvites - invitesUsed);

    await updateDoc(adminRef, {
      invitesRemaining: newInvites
    });

    console.log(`Successfully updated invites remaining. Previous: ${currentInvites}, New: ${newInvites}`);
  } catch (error) {
    console.error("Error updating admin user remaining invites:", error);
    throw error;
  }
};

export const getCampaignViewsData = async (campaignId: string) => {
  try {
    const campaignRef = doc(firestore, "influencerCampaigns", campaignId);
    const campaignDoc = await getDoc(campaignRef);
    
    if (!campaignDoc.exists()) return [];
    
    const campaignData = campaignDoc.data();
    const startDate = campaignData.startDate?.toDate() || new Date();
    const startMonth = startDate.getMonth();
    const currentMonth = new Date().getMonth();

    // Initialize or get viewsTimeSeries
    let viewsTimeSeries = campaignData.viewsTimeSeries || Array(12).fill({ instagram: 0, tiktok: 0 });

    // Calculate current total views from all collaborations
    const collabsRef = collection(firestore, "collabs");
    const q = query(collabsRef, where("influencerCampaignID", "==", campaignId));
    const collabsSnapshot = await getDocs(q);
    
    const currentViews = {
      instagram: 0,
      tiktok: 0
    };

    // Sum up all views from collaborations
    collabsSnapshot.forEach(doc => {
      const collab = doc.data();
      if (collab.deliverableLinks) {
        // Sum Instagram views
        ['instagramReels', 'instagramStories', 'instagramPosts'].forEach(type => {
          const links = collab.deliverableLinks[type] || [];
          links.forEach(link => {
            currentViews.instagram += Number(link.play_count) || 0;
          });
        });

        // Sum TikTok views
        const tiktokLinks = collab.deliverableLinks.tiktoks || [];
        tiktokLinks.forEach(link => {
          currentViews.tiktok += Number(link.play_count) || 0;
        });
      }
    });

    // Update current month with real data
    viewsTimeSeries[currentMonth] = currentViews;

    // Update Firebase with the new current month data
    await updateDoc(campaignRef, {
      viewsTimeSeries: viewsTimeSeries
    });

    // Calculate months between start and current
    const monthCount = currentMonth >= startMonth ? 
      currentMonth - startMonth + 1 : 
      12 - startMonth + currentMonth + 1;

    // Check if we need to approximate past months
    let needsApproximation = true;
    for (let i = 0; i < monthCount - 1; i++) {
      const monthIndex = (startMonth + i) % 12;
      const monthData = viewsTimeSeries[monthIndex];
      if (monthData && (monthData.instagram > 0 || monthData.tiktok > 0)) {
        needsApproximation = false;
        break;
      }
    }

    if (needsApproximation) {
      // Approximate past months between start and current
      for (let i = 0; i < monthCount - 1; i++) {
        const monthIndex = (startMonth + i) % 12;
        // Use exponential function for growth curve
        const ratio = Math.pow(i / (monthCount - 1), 1.5);
        viewsTimeSeries[monthIndex] = {
          instagram: Math.round(currentViews.instagram * ratio),
          tiktok: Math.round(currentViews.tiktok * ratio)
        };
      }

      // Update Firebase with approximated values
      await updateDoc(campaignRef, {
        viewsTimeSeries: viewsTimeSeries
      });
    }

    // Convert to ViewData format for the graph
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return Array.from({length: monthCount}, (_, i) => {
      const monthIndex = (startMonth + i) % 12;
      return {
        month: months[monthIndex],
        instagram: viewsTimeSeries[monthIndex]?.instagram || 0,
        tiktok: viewsTimeSeries[monthIndex]?.tiktok || 0
      };
    });

  } catch (error) {
    console.error("Error fetching campaign views data:", error);
    return [];
  }
};