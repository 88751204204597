import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollabsDataContext } from "./collabsDataContext";
import { Influencer } from "../views/admin/foodfluence/types";

// Define the context type as a Record of Influencers
export const InfluencersDataContext = createContext<Record<string, Influencer>>({});

export const InfluencersDataProvider = ({ children }: { children: React.ReactNode }) => {
    const [influencers, setInfluencers] = useState<Record<string, Influencer>>({});
    const collabs = useContext(CollabsDataContext);

    useEffect(() => {
        if (!collabs || Object.keys(collabs).length === 0) {
            setInfluencers({});
            return;
        }

        const unsubscribes = [];
        const uniqueInfluencerIds = new Set();

        Object.values(collabs).forEach(collab => {
            const influencerId = collab.influencerID;
            if (!influencerId || uniqueInfluencerIds.has(influencerId)) return;
            
            uniqueInfluencerIds.add(influencerId);
            const influencerDocRef = doc(firestore, "influencers", influencerId);
            const unsubscribe = onSnapshot(influencerDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setInfluencers(prev => ({
                        ...prev,
                        [influencerId]: { id: influencerId, ...data } as Influencer
                    }));
                }
            });

            unsubscribes.push(unsubscribe);
        });
        // Cleanup the listeners when the component is unmounted
        return () => {
            unsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, [collabs]);

    return (
        <InfluencersDataContext.Provider value={influencers}>
            {children}
        </InfluencersDataContext.Provider>
    );
};

export default InfluencersDataProvider;
