import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Box, AspectRatio, Image } from '@chakra-ui/react';
import { DeliverableLink } from '../../types';

interface ExpandedMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedVideo: string | null;
  selectedMedia: DeliverableLink | null;
  isVideo: (url: string) => boolean;
}

const ExpandedMediaModal: React.FC<ExpandedMediaModalProps> = ({ isOpen, onClose, selectedVideo, selectedMedia, isVideo }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay bg="rgba(0, 0, 0, 1)" />
      <ModalContent bg="black" maxW="80vw" maxH="80vh" position="relative">
        <ModalCloseButton color="white" onClick={onClose} position="absolute" right="8px" top="8px" zIndex="1" />
        <ModalBody display="flex" justifyContent="center" alignItems="center" p={4}>
          <Box width="100%" height="100%">
            {selectedVideo && (
              <AspectRatio ratio={16 / 9} width="100%" height="100%">
                <video controls autoPlay muted style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                  <source src={selectedVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </AspectRatio>
            )}
            {selectedMedia && !isVideo(selectedMedia.verifiedUGCLink) && (
              <AspectRatio ratio={1 / 1} width="60%" height="60%" justifySelf="center" alignSelf="center">
                <Image src={selectedMedia.verifiedUGCLink} alt="Expanded Media" objectFit="contain" width="100%" height="100%" />
              </AspectRatio>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExpandedMediaModal; 