import React, { useEffect, useState } from 'react';
import CollabCalendar from '../../views/admin/foodfluence/components/CollabCalendar';
import { getAllCollabsNotPendingInfluencerApproval, getCampaignsFromIDs, getAllInfluencersFromIDs, getAllClientNamesFromIDs, getAllFoodfluenceLocationsFromIDs } from 'services/firebaseService';
import { CollaborationsData, FoodfluenceLocation, Influencer, InfluencerCampaign } from '../../views/admin/foodfluence/types';

interface GlobalCollabCalendarProps {
  isOpen: boolean;
  onClose: () => void;
  setUpcomingCollabsCount: (count: number) => void;
}

const GlobalCollabCalendar: React.FC<GlobalCollabCalendarProps> = ({
  isOpen,
  onClose,
  setUpcomingCollabsCount
}) => {
  const [allCollaborations, setAllCollaborations] = useState<Record<string, CollaborationsData>>({});
  const [allCampaigns, setAllCampaigns] = useState<Record<string, InfluencerCampaign>>({});
  const [influencers, setInfluencers] = useState<Record<string, Influencer>>({});
  const [foodfluenceLocations, setFoodfluenceLocations] = useState<Record<string, FoodfluenceLocation>>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!isDataLoaded) {
        const collaborations = await getAllCollabsNotPendingInfluencerApproval();

        const [campaigns, influencers, foodfluenceLocations] = await Promise.all([
          getCampaignsFromIDs([...new Set(Object.values(collaborations).map(collab => collab.influencerCampaignID))]),
          getAllInfluencersFromIDs([...new Set(Object.values(collaborations).map(collab => collab.influencerID))]),
          getAllFoodfluenceLocationsFromIDs([...new Set(Object.values(collaborations).map(collab => collab.selectedLocation).filter(location => location !== null))])
        ]);

        console.log("Campaigns with null clientID:", Object.values(campaigns).filter(campaign => !campaign.clientID));
        const clientNames = await getAllClientNamesFromIDs([...new Set(Object.values(campaigns).filter(campaign => campaign?.clientID).map(campaign => campaign.clientID))]);

        const campaignsWithClientNames = Object.entries(campaigns).reduce((acc, [id, campaign]) => {
          return {
            ...acc,
            [id]: {
              ...campaign,
              clientName: clientNames[campaign.clientID]?.clientName || 'Unknown Client'
            }
          };
        }, {});

        setAllCollaborations(collaborations);
        setAllCampaigns(campaignsWithClientNames);
        setInfluencers(influencers);
        setFoodfluenceLocations(foodfluenceLocations);
        setIsDataLoaded(true);
      }
    };

    fetchAllData();
  }, [isDataLoaded]);

  return (
    <CollabCalendar
      campaignId={null}
      isOpen={isOpen}
      onClose={onClose}
      setUpcomingCollabsCount={setUpcomingCollabsCount}
      global={true}
      globalCollaborations={allCollaborations}
      globalCampaigns={allCampaigns}
      globalInfluencers={influencers}
      globalFoodfluenceLocations={foodfluenceLocations}
    />
  );
};

export default GlobalCollabCalendar;