import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  Input,
  IconButton,
  Select,
  Flex,
  Grid,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import FfUGCPost from "./UGCPost";
import { CollabsDataContext } from "contexts/collabsDataContext";
import { InfluencerCampaignsContext } from "contexts/influencerCampaignsDataContext";
import { InfluencersDataContext } from "contexts/influencersDataContext";
import { CollaborationsData, DeliverableLink } from "../types";
import { refreshPostMetrics } from "services/flaskService";
import { isOlderThanOneDay } from "../utils/dateUtils";

/** Compare two objects (maps) by checking keys and values */
function areMapsEqual(map1: Record<string, any>, map2: Record<string, any>): boolean {
  const keys1 = Object.keys(map1);
  const keys2 = Object.keys(map2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (map1[key] !== map2[key]) return false;
  }
  return true;
}

const FfUGCLibrary: React.FC = () => {
  const history = useHistory();
  const collaborations = useContext(CollabsDataContext);
  const influencers = useContext(InfluencersDataContext);
  const campaigns = useContext(InfluencerCampaignsContext);

  const [ugcContent, setUgcContent] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedCampaign, setSelectedCampaign] = useState<string>("");
  const [collabIDsToRefresh, setCollabIDsToRefresh] = useState<string[]>([]);

  // This ref holds our map of campaignId -> campaignName
  // We'll update it only if the computed map truly changes.
  const campaignMapRef = useRef<Record<string, string>>({});

  function isContextLoaded(
    collaborations: Record<string, any>,
    influencers: Record<string, any>,
    campaigns: Record<string, any>
  ): boolean {
    return (
      Object.keys(collaborations).length > 0 &&
      Object.keys(influencers).length > 0 &&
      Object.keys(campaigns).length > 0
    );
  }

  // Rebuild campaignMap from 'campaigns' *only* when it changes
  useEffect(() => {
    const newCampaignMap: Record<string, string> = {};
    for (const [id, campaign] of Object.entries(campaigns)) {
      // e.g. campaign.name
      newCampaignMap[id] = campaign.name;
    }
    // Update the ref only if different
    if (!areMapsEqual(campaignMapRef.current, newCampaignMap)) {
      campaignMapRef.current = newCampaignMap;
    }
  }, [campaigns]);

  useEffect(() => {
    const fetchUGCContent = async () => {
      try {
        if (!isContextLoaded(collaborations, influencers, campaigns)) {
          return;
        }

        const newUGC: any[] = [];
        const collabIDsNeedingRefresh = new Set<string>();

        Object.values(collaborations).forEach((collab: CollaborationsData) => {
          if (
            collab.deliverableLinks
          ) {
            Object.entries(collab.deliverableLinks).forEach(([key, linkArray]: any) => {
            let platform;
            let postType;
            switch (key) {
              case "tiktoks":
                platform = "tiktok";
                postType = "tiktok";
                break;
              case "instagramPosts":
                platform = "instagram";
                postType = "instagramPost";
                break;
              case "instagramReels":
                platform = "instagram";
                postType = "instagramReel";
                break;
              case "instagramStories":
                platform = "instagram";
                postType = "instagramStory";
                break;
              default:
                platform = "";
                postType = "";
            }
              if (Array.isArray(linkArray)) {
                linkArray.forEach((linkObj: DeliverableLink) => {
                  
                  if(linkObj.status === "approved") {
                   
                    const lastRefreshed = linkObj.lastRefreshed
                      ? linkObj.lastRefreshed
                        ? linkObj.lastRefreshed
                        : linkObj.lastRefreshed
                      : null;

                    if (postType !== "instagramStory"&& (isOlderThanOneDay(lastRefreshed) || !lastRefreshed)) {
                      collabIDsNeedingRefresh.add(collab.id);
                    }
                    const influencer = influencers[collab.influencerID];
                    if (linkObj.verifiedUGCLink) {
                      const ugcItem = {
                        collabId: collab.id,
                        link: linkObj.verifiedUGCLink,
                        postUrl: linkObj.userLink,
                        influencerName: influencer?.instagramHandle,
                        influencerProfilePic: influencer?.profile_pic_url,
                        collabDate: linkObj?.verifiedAt,
                        campaignName:
                          campaignMapRef.current[collab.influencerCampaignID],
                        likeCount: linkObj.like_count,
                        playCount: linkObj.play_count,
                        shareCount: linkObj.share_count,
                        commentCount: linkObj.comment_count,
                        platform: platform,
                        postType: postType,
                      };
                      newUGC.push(ugcItem);
                    }
                  }
                });
              }
            });
          }
        });

        setUgcContent(newUGC);
        setCollabIDsToRefresh(Array.from(collabIDsNeedingRefresh));
        setIsLoading(false);
      } catch (error) {
        console.error("Error processing UGC content:", error);
        setIsLoading(false);
      } 
    };

    fetchUGCContent();
  }, [collaborations, influencers, campaigns]);

  const [hasRefreshed, setHasRefreshed] = useState(false);
  const [refreshMessage, setRefreshMessage] = useState<string>("");

  useEffect(() => {
    if (collabIDsToRefresh.length > 0 && !hasRefreshed) {
      setRefreshMessage("Refreshing metrics...");
      setHasRefreshed(true);
  
      refreshPostMetrics(collabIDsToRefresh)
        .then(() => {
          setRefreshMessage(`Up to date`);
        })
        .catch((err) => {
          console.error("Failed to refresh post metrics:", err);
          setRefreshMessage("Failed to refresh metrics");
        })
    }
  }, [collabIDsToRefresh, hasRefreshed]);

  // Filter + display logic
  const uniqueUGC = ugcContent
    .filter(
      (item, idx, self) =>
        idx === self.findIndex((t) => t.postUrl === item.postUrl && t.collabId === item.collabId)
    ).sort((a, b) => {
      return b.collabDate.seconds - a.collabDate.seconds; // Descending order
    });


  const displayLinks = uniqueUGC.filter(
    (ugc) =>
      ugc?.influencerName?.toLowerCase().includes(searchInput.toLowerCase()) &&
      (selectedCampaign === "" || ugc.campaignName === selectedCampaign)
  );

  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" mb="75px" mt="50px" />
      </Center>
    );
  }

  return (
    <Box p={{ base: 4, md: 8 }}>
      <IconButton
        aria-label="Go back"
        icon={<ArrowBackIcon />}
        onClick={() => history.goBack()}
        mb={4}
      />
      <Flex alignItems="center" mb={4} justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          Content Library
        </Text>
        <Flex alignItems="center">
          {refreshMessage && (
            <>
              <Text fontSize="lg" color="gray.500" mr={6}>
                {refreshMessage}
              </Text>
              {refreshMessage === "Refreshing metrics..." && (
                <Spinner size="md" />
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Flex mb={4} gap={4}>
        <Input
          placeholder="Search by username"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          flex="5"
        />
        <Select
          placeholder="Filter by campaign"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          flex="4"
        >
          {Object.entries(campaignMapRef.current)
            .filter(([, name]) => name.trim() !== "")
            .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB))
            .map(([id, name]) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
        </Select>
        <Button
          onClick={() => { setSearchInput(''); setSelectedCampaign(''); }}
          flex="1"
        >
          Clear Filter
        </Button>
      </Flex>

      {displayLinks.length > 0 ? (
        <Grid templateColumns="repeat(auto-fill, minmax(400px, 1fr))" gap={6}>
          {displayLinks.map((ugc, idx) => (
            <FfUGCPost
              key={`${ugc.collabId}_${ugc.postType}_${idx}`}
              collabId={ugc.collabId}
              link={ugc.link}
              idx={idx}
              influencerName={ugc.influencerName}
              campaignName={ugc.campaignName}
              postUrl={ugc.postUrl}
              collabDate={ugc.collabDate}
              likeCount={ugc.likeCount}
              playCount={ugc.playCount}
              shareCount={ugc.shareCount}
              commentCount={ugc.commentCount}
              influencerProfilePic={ugc.influencerProfilePic}
              platform={ugc.platform}
              postType={ugc.postType}
            />
          ))}
        </Grid>
      ) : (
        <Center h="full">
          <Text fontWeight="medium" fontSize="22.5px" mt="50px" mb="100px">
            No UGC available
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default FfUGCLibrary;
