import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  useToast,
  Box,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Link,
  Spacer,
  Heading,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FaCreditCard, FaPlus } from 'react-icons/fa';
import { SiMastercard, SiDiscover, SiVisa, SiAmericanexpress } from 'react-icons/si';
import Environment from "environment";

const stripePromise = loadStripe(Environment.USE_TEST_MODE 
  ? 'pk_test_51MYZG6IXMMlSDVDkOXDzeYDRFoP51pjYJtj02KGYuWUcFrRYBb8u5dfKUK4RZJY5MIq6mk8Oucjc3hQVmv0Z3amP00w2enw9B1'
  : 'pk_live_51MYZG6IXMMlSDVDk176Pz9eSlHTGJ1FMwljYXOiHJNrcIhm0iuhOdiik7Ip7MP2p29HsabBZCP1bGQr8pmiolaAI00dvCo8SVw'  
);

interface PaymentFormProps {
  onSuccess: () => void;
  clientId: string;
}

function PaymentForm({ onSuccess, clientId }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);

  useEffect(() => {
    fetchSavedPaymentMethods();
  }, [clientId]);

  const fetchSavedPaymentMethods = async () => {
    try {
      const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/get-payment-methods`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': Environment.FLASK_API_KEY
        },
        body: JSON.stringify({ clientId }),
      });
      const data = await response.json();
      if (data.payment_methods && Array.isArray(data.payment_methods)) {
        setSavedPaymentMethods(data.payment_methods);
      } else {
        console.error('Unexpected response format:', data);
        setSavedPaymentMethods([]);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      setSavedPaymentMethods([]);
    }
  };

  const createSetupIntent = async () => {
    // Implement the logic to create a setup intent
    // This should make a request to your backend to create a SetupIntent
    // and return the client secret
    // For example:
    const response = await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/create-setup-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Environment.FLASK_API_KEY
      },
      body: JSON.stringify({ clientId }),
    });
    const data = await response.json();
    return data.clientSecret;
  };

  const savePaymentMethod = async (setupIntent: any) => {
    // Implement the logic to save the payment method
    // This should make a request to your backend to save the payment method
    // For example:
    await fetch(`${Environment.FLASK_HOST_URL_FOODFLUENCE}/save-payment-method`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Environment.FLASK_API_KEY
      },
      body: JSON.stringify({ 
        clientId,
        paymentMethodId: setupIntent.payment_method
      }),
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);

    try {
      if (selectedPaymentMethod === 'new') {
        const setupIntentSecret = await createSetupIntent();
        const { setupIntent, error } = await stripe.confirmCardSetup(setupIntentSecret, {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: { name: 'Jenny Rosen' }, // Replace with actual user name
          }
        });

        if (error) {
          throw new Error(error.message);
        }

        if (setupIntent) {
          // Save the new payment method
          await savePaymentMethod(setupIntent);
        }
      }

      toast({
        title: "Payment method saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onSuccess();
      fetchSavedPaymentMethods();
    } catch (error: unknown) {
      toast({
        title: "Failed to save payment method",
        description: error instanceof Error ? error.message : 'An unknown error occurred',
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardChange = (event: any) => {
    setIsCardComplete(event.complete);
  };

  const getCardIcon = (brand: string) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return <SiVisa size={32} color="#1A1F71" />;
      case 'mastercard':
        return <SiMastercard size={32} color="#EB001B" />;
      case 'amex':
        return <SiAmericanexpress size={32} color="#2E77BC" />;
      case 'discover':
        return <SiDiscover size={32} color="#FF6000" />;
      default:
        return <FaCreditCard size={32} color="#424770" />;
    }
  };

  const isConfirmButtonDisabled = () => {
    if (!selectedPaymentMethod) return true;
    if (selectedPaymentMethod === 'new' && !isCardComplete) return true;
    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={6} align="stretch" maxH="calc(70vh - 100px)" overflowY="auto" sx={{ '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md" mb={2} fontFamily="'Inter', sans-serif">Choose Payment Method</Heading>
          <Flex alignItems="center">
            <Text fontSize="sm" mr={2} fontFamily="'Inter', sans-serif">Powered by</Text>
            <Image src="https://stripe.com/img/v3/home/twitter.png" alt="Stripe" height="24px" />
          </Flex>
        </Flex>
        <RadioGroup onChange={setSelectedPaymentMethod} value={selectedPaymentMethod}>
          <Stack spacing={4}>
            {savedPaymentMethods.map(method => (
              <Radio key={method.id} value={method.id} pl={4}>
                <Box display="flex" alignItems="center" p={2} borderWidth={1} borderRadius="md" borderColor="gray.200">
                  {getCardIcon(method.brand)}
                  <Text fontWeight="medium" fontFamily="'Inter', sans-serif" ml={3}>{method.brand} ending in {method.last4}</Text>
                </Box>
              </Radio>
            ))}
            <Radio value="new" pl={4}>
              <Box display="flex" alignItems="center" p={2} borderWidth={1} borderRadius="md" borderColor="gray.200">
                <Box as={FaPlus} size="24px" color="green.500" mr={3} />
                <Text fontWeight="medium" fontFamily="'Inter', sans-serif">Add a new card</Text>
              </Box>
            </Radio>
          </Stack>
        </RadioGroup>
        {selectedPaymentMethod === 'new' && (
          <Box width="100%" mt={4} p={4} borderWidth={1} borderRadius="md" borderColor="gray.200">
            <CardElement 
              onChange={handleCardChange}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    fontFamily: "'Inter', sans-serif",
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }} 
            />
          </Box>
        )}
        <HStack spacing={4} justifyContent="flex-start" mt={-4} mb={2} pl={4}>
          <SiMastercard size={32} color="#EB001B" />
          <SiDiscover size={32} color="#FF6000" />
          <SiVisa size={32} color="#1A1F71" />
          <SiAmericanexpress size={32} color="#2E77BC" />
        </HStack>
        <Text fontSize="sm" color="gray.600" fontFamily="'Inter', sans-serif">
          By sending a collaboration offer, you acknowledge that your payment method will be charged automatically upon acceptance by the influencer. Please ensure that your payment details are accurate and up to date to avoid any disruptions in the collaboration process. For further details regarding billing, refunds, and other policies, please review our <Link color="blue.500" href="https://www.foodfluence.io/business-tos" isExternal>Terms of Service</Link>.
        </Text>
      </VStack>
      <Box position="sticky" bottom={0} bg="white" pt={4}>
        <Button
          type="submit"
          isLoading={isLoading}
          loadingText="Processing"
          bg="black"
          color="white"
          width="100%"
          height="60px"
          leftIcon={<FaCreditCard />}
          size="lg"
          fontFamily="'Inter', sans-serif"
          _hover={{ bg: "gray.800" }}
          isDisabled={isConfirmButtonDisabled()}
        >
          Confirm Payment Method
        </Button>
      </Box>
    </form>
  );
}

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPaymentMethodSaved: () => void;
  clientId: string;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({ isOpen, onClose, onPaymentMethodSaved, clientId }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent maxWidth="600px" maxHeight="80vh" borderRadius="lg" boxShadow="xl">
        <ModalHeader bg="black" color="white" borderTopRadius="lg" fontFamily="'Inter', sans-serif">Select Payment Method</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody pb={6}>
          <Elements stripe={stripePromise}>
            <PaymentForm onSuccess={onPaymentMethodSaved} clientId={clientId} />
          </Elements>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
