import React, { useState } from "react";
import { Box, Button, Flex, IconButton, Text, Avatar} from "@chakra-ui/react";
import { FaDownload, FaInstagram, FaTiktok, FaHeart, FaChartBar, FaShare, FaComment } from "react-icons/fa";
import FfUGCImage from "./UGCImage";
import { handleDownload } from "../utils/contentUtils";
import { formatCount } from "../utils/metricsUtils";

interface FfUGCPostProps {
  collabId: string;
  link: string;
  idx: number;
  influencerName: string;
  postUrl: string;
  collabDate: string;
  campaignName: string;
  likeCount: number;
  playCount: number;
  shareCount: number;
  commentCount: number;
  influencerProfilePic?: string;
  platform: string;
  postType: string;
}

const FfUGCPost: React.FC<FfUGCPostProps> = ({
  collabId,
  link,
  idx,
  influencerName,
  campaignName,
  postUrl,
  collabDate,
  likeCount,
  playCount,
  shareCount,
  commentCount,
  influencerProfilePic,
  platform,
  postType,
}) => {

  const handleViewPost = () => {
    window.open(postUrl, "_blank");
  };

  // Determine the platform based on the postUrl
  const getPlatformIcon = () => {
    if (platform === "instagram") {
      return <FaInstagram size="20px" />;
    } else if (platform === "tiktok") {
      return <FaTiktok size="20px" />;
    }
    return null;
  };


  return (
    <Box
      mb={4}
      w="100%"
      minW="400px"
      maxW="400px"
      overflow="hidden"
      borderRadius="md"
      shadow="md"
      p={2}
      position="relative"
      transition="border-color 0.2s"
      border="1px solid transparent"
      _hover={{
        borderColor: "gray.600",
        cursor: "pointer",
      }}
      height="auto"
    >
      <Box
        onClick={(e) => e.stopPropagation()}
      >
        <FfUGCImage link={link} idx={idx} postUrl={postUrl} collabDate={collabDate} platform={platform} postType={postType} campaignName={campaignName}/>
      </Box>
      <Box onClick={handleViewPost}>
        <Flex mt={2} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Avatar size="sm" src={influencerProfilePic} name={influencerName} ml="2" />
            <Text fontWeight="bold" ml="3px">@{influencerName} </Text>
          </Flex>
          <Flex alignItems="center">
            <IconButton
              aria-label="Download"
              icon={<FaDownload />}
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(link, collabId);
              }}
              variant="ghost"
              size="sm"
              mr={2}
            />
            {<Box mr={2}>{getPlatformIcon()}</Box>}
          </Flex>
        </Flex>
        <Box mt={5} px={7}>
          <Flex justifyContent="space-between" alignItems="center" gap={4}>
            {postType === "instagramStory" ? (
              <Text textAlign="center" w="100%" fontSize="sm">Metrics are not available for stories</Text>
            ) : (
              <>
                {(postType === "instagramPost" || postType === "instagramReel" || postType === "tiktok") && (
                  <Flex alignItems="center">
                    <FaHeart />
                    <Text ml={1} fontSize="sm">{formatCount(likeCount)} likes</Text>
                  </Flex>
                )}
                {(postType === "instagramReel" || postType === "tiktok") && (
                  <>
                    <Flex alignItems="center">
                      <FaChartBar />
                      <Text ml={1} fontSize="sm">{formatCount(playCount)} plays</Text>
                    </Flex>
                    <Flex alignItems="center">
                      <FaShare />
                      <Text ml={1} fontSize="sm">{formatCount(shareCount)} shares</Text>
                    </Flex>
                  </>
                )}
                {postType === "instagramPost" && (
                  <Flex alignItems="center">
                    <FaComment />
                    <Text ml={1} fontSize="sm">{formatCount(commentCount)} comments</Text>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default FfUGCPost; 