import React from 'react';
import { Box, Image, IconButton, Flex, Text, AspectRatio, VStack, Link, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { FaPlay, FaHeart, FaComment, FaDownload, FaEllipsisV, FaExternalLinkAlt, FaEllipsisH } from 'react-icons/fa';
import { DeliverableLink } from '../../types';
import saveAs from 'file-saver';
import { isVideo } from '../../utils/contentUtils';
import { formatCount } from '../../utils/metricsUtils';

interface MediaComponentProps {
  media: DeliverableLink;
  onPlayPause: (e: React.MouseEvent, mediaUrl: string) => void;
  index: number;
  onMediaClick: (media: DeliverableLink) => void;
}

const MediaComponent: React.FC<MediaComponentProps> = ({ media, onPlayPause, index, onMediaClick }) => {
    const handleDownload = () => {
      const urlWithoutParams = media.verifiedUGCLink.split('?')[0];
      
      const fileExtension = urlWithoutParams.split('.').pop();
    
      const fileName = `Media_${index + 1}.${fileExtension}`;
      
      saveAs(media.verifiedUGCLink, fileName);
    };

  return (
    <Box
      key={index}
      borderWidth={1}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      cursor="pointer"
      transition="all 0.3s"
      _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
      onClick={() => onMediaClick(media)}
      position="relative"
    >
      <VStack spacing={3} p={4} height="100%">
        <AspectRatio ratio={1} width="100%">
          <Box position="relative" width="100%" height="100%">
            {isVideo(media.verifiedUGCLink) ? (
              <>
                <video onClick={(e) => e.stopPropagation()} width="100%" height="100%">
                  <source src={media.verifiedUGCLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <IconButton
                  icon={<FaPlay />}
                  aria-label="Play"
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                  fontSize="2xl"
                  color="white"
                  background="rgba(0, 0, 0, 0.5)"
                  borderRadius="full"
                  onClick={(e) => onPlayPause(e, media.verifiedUGCLink)}
                />
              </>
            ) : (
              <Image
                src={media.verifiedUGCLink}
                alt={`Media ${index + 1}`}
                objectFit="cover"
                width="100%"
                height="100%"
                fallbackSrc="https://via.placeholder.com/150"
              />
            )}
          </Box>
        </AspectRatio>
        <Flex p={2} width="100%" justifyContent="space-between">
          {media.like_count > 0 && (
            <Flex alignItems="center">
              <FaHeart color="grey" />
              <Text color="grey" ml={1}>{formatCount(media.like_count)}</Text>
            </Flex>
          )}
          {media.comment_count > 0 && (
            <Flex alignItems="center">
              <FaComment color="grey" />
              <Text color="grey" ml={1}>{formatCount(media.comment_count)}</Text>
            </Flex>
          )}
        </Flex>
        {media.caption && (
          <Text fontSize="sm" color="gray.600" width="100%" noOfLines={2}>
            {media.caption}
          </Text>
        )}
        <Box flexGrow={1} />
      </VStack>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaEllipsisH />}
          variant="ghost"
          aria-label="Options"
          position="absolute"
          top={3}
          right={3}
          size="sm"
          color="gray.600"
          backgroundColor="white"
          opacity={0.8}
          _hover={{
            opacity: 1,
            backgroundColor: 'white'
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList
          zIndex={1000}
          onClick={(e) => e.stopPropagation()}
        >
          <MenuItem 
            icon={<FaDownload />} 
            onClick={(e) => {
              e.stopPropagation();
              handleDownload();
            }}
          >
            Download
          </MenuItem>
          <MenuItem 
            icon={<FaExternalLinkAlt />} 
            as="a" 
            href={media.userLink} 
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            Go to Post
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default MediaComponent;
