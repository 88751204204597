import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Button,
  VStack,
  Flex,
  Icon,
  useToast,
  IconButton,
  Link,
  HStack,
} from '@chakra-ui/react';
import { FaInstagram, FaTiktok, FaEnvelope } from 'react-icons/fa';
import { CollaborationsData, Influencer, InfluencerCampaign } from '../../types';
import { acceptCounter, rejectCounter } from '../../../../../services/firebaseService';
import CounterBackCollabModal from './CounterBackCollabModal';
import { sendCounterText } from 'services/flaskService';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { InfluencersDataContext } from 'contexts/influencersDataContext';

interface CounteredCollabOptionsProps {
  isOpen: boolean;
  onClose: () => void;
  collab: CollaborationsData;
  campaign: InfluencerCampaign;
  influencer: Influencer;
  clientName: string; 
}

const deliverableTypeToTask: { [key: string]: string } = {
  instagramPosts: "Post/Tag on Instagram Feed",
  instagramReels: "Post/Tag on Instagram Reels",
  instagramStories: "Post/Tag on Instagram Story",
  tiktoks: "Post/Tag on TikTok",
};

const CounteredCollabOptions: React.FC<CounteredCollabOptionsProps> = ({ isOpen, onClose, collab, campaign, influencer, clientName }) => {
  const toast = useToast();
  const [isCounterModalOpen, setIsCounterModalOpen] = useState(false);
  const [wasUpdated, setWasUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setWasUpdated(false);
    }
  }, [isOpen]);

  // if (!collab || !influencer) return null;

  const handleAccept = async (collabId: string) => {
    try {
      await acceptCounter(collabId, collab.proposedInfluencerDeliverableCounts, collab.proposedInfluencerCash, collab.proposedInfluencerCredit);
      sendCounterText(influencer.phoneNumber, influencer.firstName, clientName);
      onClose();
      toast({
        title: "Collab Accepted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error accepting collab:", error);
    }
  };

  const handleConfirmReject = async (collabId: string) => {
    try {
      rejectCounter(collabId);
      sendCounterText(influencer.phoneNumber, influencer.firstName, clientName);
      onClose();
      toast({
        title: "Counter Rejected",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error accepting collab:", error);
    }
  }

  /** Could be code for possible cancel collab flow
  const handleConfirmReject = async (collabId: string) => {
    try {
      await updateCollabStatus(collabId, "collabCancelled", {});
      console.log("Collab rejected by business.");
      onClose();
      closeCounteredCalendar();
      toast({
        title: "Collab Cancelled",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error rejecting collab:", error);
    }
  };
   */

  const handleCounter = () => {
    setIsCounterModalOpen(true);
    onClose();
  };

  const handleCounterModalClose = () => {
    setIsCounterModalOpen(false);
    if (!wasUpdated) {
      setTimeout(() => onClose(), 0);
    }
  };

  const handleUpdate = () => {
    setWasUpdated(true);
    setIsCounterModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="40vw" borderRadius="xl" boxShadow="lg" py={8}>
          <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">
            <VStack spacing={2}>
              <Text>Counter by @{influencer?.instagramHandle}</Text>
              <HStack spacing={3} justify="center">
                {influencer?.instagramHandle && (
                  <IconButton
                    icon={<FaInstagram />}
                    aria-label="Instagram"
                    variant="ghost"
                    colorScheme="gray"
                    as={Link}
                    href={`https://www.instagram.com/${influencer?.instagramHandle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="lg"
                  />
                )}
                {influencer?.tiktokHandle && (
                  <IconButton
                    icon={<FaTiktok />}
                    aria-label="TikTok"
                    variant="ghost"
                    colorScheme="gray"
                    as={Link}
                    href={`https://www.tiktok.com/@${influencer?.tiktokHandle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="lg"
                  />
                )}
              </HStack>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5} align="stretch">
              <Box border="1px solid" borderColor="gray.200" p={3} borderRadius="md" >
                <Text fontWeight="bold" fontSize="xl" mb={2}>Deliverables</Text>
                {Object.entries(collab?.proposedInfluencerDeliverableCounts).map(([key, value]) => (
                  value > 0 && (
                    <Flex key={key} align="center">
                      <Icon mb={2} as={key.includes('instagram') ? FaInstagram : FaTiktok} mr={2} />
                      <Text fontSize="lg" mb={2}>{value} {deliverableTypeToTask[key]}</Text>
                    </Flex>
                  )
                ))}
              </Box>
              <Box border="1px solid" borderColor="gray.200" p={3} borderRadius="md" >
                <Text fontWeight="bold" fontSize="xl" mb={2}>Compensation</Text>
                <Flex align="center" mt={2}>
                  <Text fontSize="lg">Cash: ${collab?.proposedInfluencerCash || '0'}</Text>
                </Flex>
                <Flex align="center" mt={2}>
                  <Text fontSize="lg">Credit: ${collab?.proposedInfluencerCredit || '0'}</Text>
                </Flex>
              </Box>
            </VStack>
            <Box mt={6}>
              <Flex justifyContent="center" gap={4}>
                <Button 
                  bg="black" 
                  color="white" 
                  width="48%"
                  maxW="200px"
                  size="md" 
                  onClick={() => handleAccept(collab.id)}
                >
                  Accept
                </Button>
                <Button 
                  bg="white" 
                  color="black" 
                  border="1px solid" 
                  borderColor="grey" 
                  width="48%"
                  maxW="200px"
                  size="md" 
                  onClick={handleCounter}
                >
                  Counter
                </Button>
              </Flex>
            </Box>
            <Text mt={4} textAlign="center" fontSize="sm">
              Don't want to counter? <Link color="blue.500" onClick={() => handleConfirmReject(collab.id)}>Click here to decline this offer.</Link>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CounterBackCollabModal
        isOpen={isCounterModalOpen}
        onClose={handleCounterModalClose}
        onUpdate={handleUpdate}
        collab={collab}
        campaign={campaign}
        influencer={influencer}
        clientName={clientName}
      />
    </>
  );
};

export default CounteredCollabOptions;
