import React, { useContext } from 'react';
import {
  Box,
  Flex,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import { FaInstagram, FaStar, FaTiktok } from 'react-icons/fa';
import { InfluencersDataContext } from 'contexts/influencersDataContext';
import { CollabsDataContext } from 'contexts/collabsDataContext';
import { InfluencerCampaignsContext } from 'contexts/influencerCampaignsDataContext';
import { AccountsDataContext } from 'contexts/accountsDataContext';
import InstagramProfilePic from 'views/admin/foodfluence/components/InstagramProfilePic';
import { formatCount } from 'views/admin/foodfluence/utils/metricsUtils';
import StatusButton from 'views/admin/foodfluence/components/StatusButton';

interface CollabTableProps {
  campaignId: string;
}

const CollabTable: React.FC<CollabTableProps> = ({ campaignId }) => {
  const influencers = useContext(InfluencersDataContext);
  const collabsData = useContext(CollabsDataContext);
  const campaigns = useContext(InfluencerCampaignsContext);
  const accountsData = useContext(AccountsDataContext);

  // Filter collaborations for this campaign
  const campaignCollabs = Object.values(collabsData).filter(
    collab => collab.influencerCampaignID === campaignId
  );

  // Sort collaborations by status (completed first)
  const sortedCollaborations = campaignCollabs.sort((a, b) => {
    const priorityStatuses = ['collabCompleted'];
    if (priorityStatuses.includes(a.status) && !priorityStatuses.includes(b.status)) {
      return -1;
    }
    if (!priorityStatuses.includes(a.status) && priorityStatuses.includes(b.status)) {
      return 1;
    }
    return 0;
  });

  return (
    <Box overflowY="auto" flex={1} px={2} mb={8}>
      {sortedCollaborations.map((collaboration) => {
        const influencer = influencers[collaboration.influencerID];
        const campaign = campaigns[collaboration.influencerCampaignID];
        const clientInfo = accountsData[campaign?.clientID];
        const clientName = clientInfo?.accountName;

        return (
          <Flex 
            key={collaboration.id}
            direction="row" 
            gap={6}
            align="center"
            position="relative"
            bg="white"
            py={4}
            px={8}
            mt={4}
            borderRadius="xl"
            border="1px solid"
            borderColor="gray.300"
            minHeight="100px"
            _hover={{ 
              bg: "gray.50",
              transform: "translateY(-2px)",
              boxShadow: "md",
              borderColor: "blue.300",
            }}
          >
            <InstagramProfilePic
              profile_pic_url={influencer?.profile_pic_url}
              instagramHandle={influencer?.instagramHandle}
              imageSize={"90px"}
            />

            <Flex direction="column" gap={3} flex={1}>
              <Flex justify="space-between" align="center" mb={-1}>
                <HStack spacing={3}>
                  <Text fontSize="lg" fontWeight="semibold">
                    @{influencer?.instagramHandle}
                  </Text>
                  {influencer?.featured && <FaStar color="#0080FE" size="20px" />}
                </HStack>
              </Flex>

              <HStack spacing={4} color="gray.600">
                {influencer?.instagramHandle && (
                  <HStack spacing={2}>
                    <Button 
                      as="a" 
                      href={`https://instagram.com/${influencer.instagramHandle}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      variant="link" 
                      size="md"
                      p={0}
                    >
                      <FaInstagram size="18px" />
                    </Button>
                    <Text fontSize="md">
                      {formatCount(influencer.follower_count)} followers
                    </Text>
                  </HStack>
                )}
                {influencer?.tiktokHandle && influencer?.tiktokFollowers > 0 && (
                  <>
                    <Text fontSize="md">|</Text>
                    <HStack spacing={2}>
                      <Button 
                        as="a" 
                        href={`https://www.tiktok.com/@${influencer.tiktokHandle}`} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        variant="link" 
                        size="md"
                        p={0}
                      >
                        <FaTiktok size="18px" />
                      </Button>
                      <Text fontSize="md">
                        {formatCount(influencer.tiktokFollowers)} followers
                      </Text>
                    </HStack>
                  </>
                )}
                {influencer?.state && influencer.state.toLowerCase() !== 'unknown' && (
                  <>
                    <Text fontSize="md">|</Text>
                    <Text fontSize="md">
                      {influencer.city && influencer.city.toLowerCase() !== 'unknown' && `${influencer.city}, `}{influencer.state}
                    </Text>
                  </>
                )}
              </HStack>
            </Flex>

            <Box position="absolute" right={6} top="50%" transform="translateY(-50%)">
              <StatusButton
                status={collaboration.status}
                collaboration={collaboration}
                campaign={campaign}
                influencer={influencer}
                clientName={clientName}
                size="sm"
              />
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

export default CollabTable;
