import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  Flex,
  IconButton,
  useToast
} from '@chakra-ui/react';
import { FaCheck, FaTrash } from 'react-icons/fa';
import AddressSelector from './addressSelector';
import { FoodfluenceLocation } from '../types';
import { createFoodfluenceLocation, deleteFoodfluenceLocation, getClientFoodfluenceLocations, updateFoodfluenceLocationEmails } from 'services/firebaseService';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientID: string;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  onClose,
  clientID
}) => {
  const [locations, setLocations] = useState<FoodfluenceLocation[]>([]);
  const [locationEmails, setLocationEmails] = useState<Record<string, string[]>>({});
  const [newEmails, setNewEmails] = useState<Record<string, string>>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [initialLocations, setInitialLocations] = useState<FoodfluenceLocation[]>([]);
  const [initialLocationEmails, setInitialLocationEmails] = useState<Record<string, string[]>>({});
  const toast = useToast();

  useEffect(() => {
    const fetchClientLocations = async () => {
      const clientLocations = await getClientFoodfluenceLocations(clientID);
      setLocations(clientLocations);
      setLocationEmails(clientLocations.reduce((acc, loc) => {
        acc[loc.fullAddress] = loc.emails || [];
        return acc;
      }, {} as Record<string, string[]>));
      setInitialLocations(clientLocations);
      setInitialLocationEmails(clientLocations.reduce((acc, loc) => {
        acc[loc.fullAddress] = loc.emails || [];
        return acc;
      }, {} as Record<string, string[]>));
    };
    fetchClientLocations();
  }, [clientID]);

  useEffect(() => {
    const hasLocationChanges = JSON.stringify(locations) !== JSON.stringify(initialLocations);
    const hasEmailChanges = JSON.stringify(locationEmails) !== JSON.stringify(initialLocationEmails);
    setHasChanges(hasLocationChanges || hasEmailChanges);
  }, [locations, locationEmails, initialLocations, initialLocationEmails]);

  const handleAddEmail = (locationId: string) => {
    if (!newEmails[locationId] || !newEmails[locationId].includes('@')) {
      toast({
        title: 'Invalid email',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    setLocationEmails(prev => {
      const existing = prev[locationId];
      if (existing) {
        return {
          ...prev,
          [locationId]: [...existing, newEmails[locationId]]
        };
      }
      return {
        ...prev,
        [locationId]: [newEmails[locationId]]
      };
    });
    setNewEmails(prev => ({
      ...prev,
      [locationId]: ''
    }));
  };

  const handleRemoveEmail = (locationAddress: string, email: string) => {
    setLocationEmails(prev => ({
      ...prev,
      [locationAddress]: prev[locationAddress].filter(e => e !== email)
    }));
  };

  const handleLocationRemoved = (removedLocationAddress: string) => {
    setLocations(prevLocations => prevLocations.filter(loc => loc.fullAddress !== removedLocationAddress));
    setLocationEmails(prev => {
      const { [removedLocationAddress]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleSave = async () => {
    try {
      // Delete locations that are no longer in the locations array
      for (const initialLocation of initialLocations) {
        if (!locations.find(loc => loc.fullAddress === initialLocation.fullAddress)) {
          await deleteFoodfluenceLocation(clientID, initialLocation.fullAddress);
        }
      }

      // Save or update locations
      for (const location of locations) {
        if (initialLocations.some(loc => loc.fullAddress === location.fullAddress)) {
          await updateFoodfluenceLocationEmails(location, locationEmails[location.fullAddress]);
        } else {
          await createFoodfluenceLocation(location, clientID, locationEmails[location.fullAddress]);
        }
      }
      toast({
        title: 'Settings saved',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      toast({
        title: 'Error saving settings',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const isValidEmail = (email: string) => {
    return email && email.includes('@');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Location Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            <AddressSelector
              locations={locations || []}
              setLocations={setLocations}
              isRequired={false}
            />
            
            {(locations || []).map((location) => (
              <Box key={location.fullAddress} p={4} borderWidth={1} borderRadius="md">
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">{location.fullAddress}</Text>
                  <IconButton
                    aria-label="Remove location"
                    icon={<FaTrash />}
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => handleLocationRemoved(location.fullAddress)}
                  />
                </Flex>
                <VStack mt={2} align="stretch">
                  {(locationEmails[location.fullAddress] || []).map((email) => (
                    <Flex key={email} justify="space-between" align="center">
                      <Text>{email}</Text>
                      <IconButton
                        aria-label="Remove email"
                        icon={<FaTrash />}
                        size="sm"
                        onClick={() => handleRemoveEmail(location.fullAddress, email)}
                      />
                    </Flex>
                  ))}
                  <Flex>
                    <Input
                      placeholder="Add email"
                      value={newEmails[location.fullAddress] || ''}
                      onChange={(e) => setNewEmails(prev => ({
                        ...prev,
                        [location.fullAddress]: e.target.value
                      }))}
                      mr={2}
                    />
                    <IconButton
                      aria-label="Add email"
                      icon={<FaCheck />}
                      variant="ghost"
                      colorScheme="green"
                      onClick={() => handleAddEmail(location.fullAddress)}
                      isDisabled={!isValidEmail(newEmails[location.fullAddress] || '')}
                    />
                  </Flex>
                </VStack>
              </Box>
            ))}
          </VStack>
          <Button mt={4} colorScheme="green" onClick={handleSave} isDisabled={!hasChanges}>
            Save Settings
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
