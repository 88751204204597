import { Timestamp } from "firebase/firestore";
import CanceledCollabModal from "./components/CanceledCollabModal";
import CollabInviteView from "./components/CollabInviteView";
import CounteredCollabOptions from "./components/counter-flow/CounterCollabOptions";
import RejectedCollabModal from "./components/RejectedCollabModal";
import ShippingConfirmationModal from "./components/shippingConfirmationModal";
import CollabContentModal from "./components/collab-content/CollabContentModal";
import UpcomingInStoreCollabModal from "./components/UpcomingInStoreCollabModal";
import UpdateTrackingModal from "./components/updateTrackingModal";
import { getAllCompletedDeliverables } from "./utils/collabsUtils";

export interface Influencer {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    email_address: string;
    instagramHandle: string;
    tiktokHandle: string;
    state: string;
    city: string;
    profilePic: any;
    profile_pic_url: string;
    follower_count: number;
    featured: boolean;
    tags: string[];
    phoneNumber?: string;
    engagement_rate: number;
    tiktokEngagementRate?: number;
    displayEngagement?: number;
    tiktokFollowers?: number;
 }

export interface OpenHours {
    Monday: Set<string>;
    Tuesday: Set<string>;
    Wednesday: Set<string>;
    Thursday: Set<string>;
    Friday: Set<string>;
    Saturday: Set<string>;
    Sunday: Set<string>;
}

export interface InfluencerCampaign {
    id: string;
    clientID: string;
    name: string;
    description: string;
    deliverables: any;
    cash: number;
    credit: number;
    startDate: Timestamp | null;
    endDate: Timestamp | null;
    collabImage: string;
    deliverableCounts: DeliverableCounts;
    openHours: OpenHours;
    foodfluenceLocations: string[];
    clientName: string;
    allowCounters: boolean;
    campaignType: string;
    maxPartySize: number;
}

export interface CollaborationsData {
    id: string;
    influencerID: string;
    influencerCampaignID: string;
    cash: number;
    credit: number;
    deliverableCounts: DeliverableCounts;
    deliverableLinks: {
      [key: string]: DeliverableLink[];
    };
    offerRedeemedDate: Date;
    logs: any;
    status: string;
    scheduledCollabDate: Timestamp;
    collabReceivedTime: Timestamp;
    collabCounteredTime: Timestamp;
    collabCanceledTime: Timestamp;
    collabAcceptedTime: Timestamp;
    shippingAddress?: string;
    shippingConfirmed?: Timestamp;
    proposedInfluencerDeliverableCounts?: DeliverableCounts;
    proposedInfluencerCash?: number;
    proposedInfluencerCredit?: number;
    selectedLocation: string;
    trackingNumbers?: string[];
    influencerRejectionReason?: string;
    partySize?: number | null;
    influencerCancelReason?: string;
}

export interface FoodfluenceLocation {
    docId: string;
    id: string;
    fullAddress: string;
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    lat: number;
    lng: number;
    timezone: string;
    emails: string[];
    clientID: string;
}

export interface DeliverableCounts {
    tiktoks: number;
    instagramReels: number;
    instagramStories: number;
    instagramPosts: number;
}

export type DeliverableType = 'tiktoks' | 'instagramReels' | 'instagramStories' | 'instagramPosts';

export interface DeliverableLink {
    userLink: string;
    verifiedUGCLink: string;
    status: 'pendingSubmission' | 'pendingVerification' | 'approved';
    like_count: number;
    comment_count: number;
    lastRefreshed: Date;
    play_count: string;
    share_count: number;
    caption: string;
    verifiedAt: Date;
}

export const collabStates = (collab: CollaborationsData): { label: string; color: string, component?: React.FC<any> } => {
  
  const states: Record<string, { label: string; color: string, component?: React.FC<any> }> = {
    pendingInfluencerApproval: {
      label: 'Pending Influencer Approval',
      color: 'gray.300', 
      component: CollabInviteView
    },
    pendingRedemption: {
      label: 'Upcoming Collab',
      color: 'gray.300',
      component: UpcomingInStoreCollabModal
    },
    collabRedeemed: {
      label: 'Pending Reward Redemption', 
      color: '#B8E79E',
    },
    pendingSubmission: {
      label: getAllCompletedDeliverables(collab)?.length > 0 ? 'View Current Content' : 'Awaiting Content Submission',
      color: 'gray.300',
      component: getAllCompletedDeliverables(collab)?.length > 0 ? CollabContentModal : null
    },
    collabRejected: {
      label: 'Collab Declined',
      color: 'red.300',
      component: RejectedCollabModal
    },
    collabCanceled: {
      label: 'Collab Canceled',
      color: 'red.300',
      component: CanceledCollabModal
    },
    collabExpired: {
      label: 'Collab Expired',
      color: 'red.300',
    },
    collabCompleted: {
      label: 'View Completed Collab',
      color: '#B8E79E',
      component: CollabContentModal
    },
    influencerCountered: {
      label: 'View Counter',
      color: 'gray.300',
      component: CounteredCollabOptions
    },
    pendingShipmentConfirmation: {
      label: 'Confirm Shipment',
      color: '#B8E79E',
      component: ShippingConfirmationModal
    },
    pendingSubmissionShipToHome: {
      label: 'Tracking Info',
      color: '#B8E79E',
      component: UpdateTrackingModal
    }
  };

  if (collab.status === 'collabCompleted') {
    return states.collabCompleted;
  }

  if (collab.shippingAddress) {
    if (!collab.shippingConfirmed) {
      return states.pendingShipmentConfirmation;
    } else {
      return states.pendingSubmissionShipToHome;
    }
  }

  return states[collab.status]
};
